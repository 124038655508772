import React from "react";
import { Box, useMediaQuery } from "@mui/material";

import Card from "../../../common/components/data-display/Card";
import VideoGalleryItem from "./GalleryVideo";

const MediaCard = ({ mediaItem, index, handleClick }) => {
  const desktop = useMediaQuery((theme) => theme.breakpoints.up("md"));

  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      justifyContent="center"
      alignItems={index % 2 === 0 ? "flex-end" : "flex-start"}
      width={"100%"}
      height={"100%"}
    >
      <Box
        width={desktop ? "50%" : "80%"}
        style={{ cursor: "pointer" }}
        onClick={handleClick}
      >
        <Card
          style={{ height: mediaItem.type === "imagine" ? "100%" : "auto" }}
        >
          {mediaItem.type === "imagine" ? (
            <img src={mediaItem.src} alt="alt" className={`gallery-img`} />
          ) : (
            <VideoGalleryItem src={mediaItem.src} noPlay />
          )}
        </Card>
      </Box>
    </Box>
  );
};

export default MediaCard;
