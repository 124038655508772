import React from "react";
import { Collapse, Grid, useMediaQuery } from "@mui/material";

// Components
import ArticleEntry from "../../common/components/data-display/ArticleEntry";
import { useTheme } from "@mui/styles";

export default function ArticleGrid({ articles, showAll }) {
  const articleEntrySize = { lg: 286, sm: 530 };

  const theme = useTheme();
  const matchesMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Collapse
      style={{ padding: 2 }}
      in={showAll}
      timeout={400}
      collapsedSize={matchesMobile ? articleEntrySize.sm : articleEntrySize.lg}
    >
      <Grid container spacing={2}>
        {articles.map((article, index) => (
          <Grid item md={4} sm={6} xs={12} key={index}>
            <ArticleEntry {...article} />
          </Grid>
        ))}
      </Grid>
    </Collapse>
  );
}
