import React from 'react';
import { Box, Grid } from '@mui/material';
import { makeStyles } from "@mui/styles";

export default function HeroImage() {
    const classes = useStyles()

    return (
        <div className={classes.root}>
            <Box display="flex" className={classes.highlight}>
                <Grid container spacing={4} justifyContent="center" alignItems="center" alignContent="center" >
                    <Grid item xs={12} sm={6} md={8} align="center">
                        <div className={classes.title}>Dr. Mihai Vârlan</div>
                        <div>Medic primar urologie</div>
                        <div>Doctor în științe medicale</div>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} align="center">
                        <img src="./media/profile-hero2.png" height="400" className={classes.profileImage} alt="alt" />
                    </Grid>
                </Grid>
            </Box>
        </div>
    )
}

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        justifyContent: 'space-evenly',
        alignItems: 'flex-end',
        height: 550,
        top: 0,
        width: '100%',
        position: 'relative',
        background: 'url(./media/heromic-copy.jpg) no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundColor:  'white',

        [theme.breakpoints.down('sm')] : {
            paddingTop: 100,
            boxSizing: 'unset',
        },
    },
    highlight: {
        '& p': {
            fontSize: '20px !important',
        },
    },
    profileImage: {
        borderRadius: 10,
    },
    title: {
        fontFamily: "'Italianno', cursive",
        fontSize: 64,
    }
}))