import React, { useState, useEffect, useCallback } from "react";
import { Typography } from "@mui/material";
import { useParams } from "react-router-dom";

// Components
import { PaddedContainer } from "../../common/layout/containers";
import VideoCard from "../../common/components/data-display/VideoCard";

// Data
import { API_ROUTES } from "../../../api/api-routes";
import Api from "../../../api/Api";
import BackNavigationButton from "../../common/components/navigation/BackNavigationButton";
import { ROUTES } from "../../../types/enum";
import { makeStyles } from "@mui/styles";

export default function VideoDetailPage() {
  const [video, setVideo] = useState(null);
  const classes = useStyles();

  let { nr } = useParams();

  const getData = useCallback(async () => {
    const video = await Api.get(API_ROUTES.VIDEOS + "/" + nr);
    setVideo(video);
  }, [nr]);

  useEffect(() => {
    getData();
  }, [getData]);

  const errorMessage = "Imi pare rau, acest video nu este disponibil momentan.";

  return (
    <PaddedContainer>
      <BackNavigationButton
        route={ROUTES.VIDEOS}
        text={"Inapoi la toate episoadele"}
      />

      {!video && <Typography align="center">{errorMessage}</Typography>}
      {video && (
        <>
          <Typography variant="subtitle1" gutterBottom align="center">
            Pastila de urologie, Episodul {video?.number}
          </Typography>
          <Typography variant="h6" gutterBottom align="center" className={classes.title}>
            {video?.title}
          </Typography>
          <hr />

          <VideoCard videoUrl={video.link} noHover />
        </>
      )}
    </PaddedContainer>
  );
}

const useStyles = makeStyles((theme) => ({
  title: {
    fontWeight: "bold !important",
    color: `${theme.palette.primary.darkText} !important`
  },
}));