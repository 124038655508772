import { Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";

export default function Paragraph({ text, subtitle = null }) {
  const classes = useStyles();
  return (
    <>
      {subtitle && (
        <Typography variant="subtitle1" gutterBottom align="justify" paragraph>
          {subtitle}
        </Typography>
      )}
      <Typography
        gutterBottom
        align="justify"
        paragraph
        className={classes.paragraphText}
      >
        {text}
      </Typography>
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  subtitle: {
    fontFamily: `${theme.fonts.article} !important`,
    fontWeight: 'bold'
  },
  paragraphText: {
    fontFamily: `${theme.fonts.article} !important`,
    textIndent: "30px",
    paddingBottom: 10,
    fontSize: "18px !important",
    fontWeight: 300,
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px !important",
    },
  },
}));
