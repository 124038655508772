import React from "react";

import "./styles.scss";

export default function Card({
  children,
  link = null,
  padded = false,
  className = "",
  noHover = false,
  hero = false,
  onClick,
}) {
  const cardBody = () => (
    <div
      onClick={onClick}
      className={`card ${className} ${padded ? "padded" : ""} ${
        noHover ? "no-hover" : ""
      } ${hero ? "hero" : ""}`}
    >
      {children}
    </div>
  );

  return <>{link ? <a href={link}>{cardBody()}</a> : cardBody()}</>;
}
