import React from "react";
import { Grid, Box, useMediaQuery, Typography } from "@mui/material";
import { makeStyles, useTheme } from "@mui/styles";
import grey from "@mui/material/colors/grey";

// Components
import { PaddedContainer } from "./containers";
import SocialLinks from "../components/social/SocialLinks";

const Footer = () => {
  const classes = useStyles();

  const theme = useTheme();
  const matchesDesktop = useMediaQuery(theme.breakpoints.up("md"));

  const DesktopFooter = () => (
    <>
      <Grid item sm={12} md={6} alignContent="center">
        <Box py={3}>
          <Typography>Copyright &#169; 2022 Mihai Vârlan</Typography>
        </Box>
      </Grid>
      <Grid item sm={12} md={6} alignItems="flex-end">
        <SocialLinks />
      </Grid>
    </>
  );

  const MobileFooter = () => (
    <Box pt={6}>
      <Grid item sm={12} md={6} alignItems="center">
        <SocialLinks />
      </Grid>
      <Grid item sm={12} md={6}>
        <Box pt={6} pb={3} display="flex" justifyContent="center">
          <Typography>Copyright &#169; 2022 Mihai Vârlan</Typography>
        </Box>
      </Grid>
    </Box>
  );

  return (
    <footer className={classes.footer}>
      <PaddedContainer>
        <Grid
          container
          className={classes.grid}
          alignContent="center"
          alignItems="center"
        >
          {matchesDesktop ? <DesktopFooter /> : <MobileFooter />}
        </Grid>
      </PaddedContainer>
    </footer>
  );
};

const useStyles = makeStyles((theme) => ({
  footer: {
    width: "100%",
    backgroundColor: theme.palette.common.footer,
    borderTop: `1px solid ${theme.palette.common.grey}`,
    color: grey[600],
    display: "flex",
  },
  grid: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
}));

export default Footer;
