import React from "react";
import { Typography, Box } from "@mui/material";

import ArticleGrid from "./ArticleGrid";

export default function ReadMoreSection({ articles, showAll = false }) {
  return (
    <Box pt={3} style={{ transition: "display 0.3s ease-in-out" }}>
      <Typography variant="h6" gutterBottom>
        Citește și...
      </Typography>
      <ArticleGrid articles={articles} showAll={showAll} />
    </Box>
  );
}
