import React, { useEffect, useState } from "react";
import { Box, Typography, Container } from "@mui/material";
import { makeStyles } from "@mui/styles";

// Components
import { PaddedContainer } from "../../common/layout/containers";
import Gallery from "../../common/components/gallery/ImageGallery";

// data
import { textFragments } from "../../data-source/homepageText";
import { gallerySections } from "../../data-source/homepageImages";
import Carousel from "./HomePageCarousel";
import { API_ROUTES } from "../../../api/api-routes";
import Api from "../../../api/Api";

export default function HomePage() {
  const classes = useStyles();

  const [latestResources, setLatestResources] = useState({});

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    const latest = await Api.get(API_ROUTES.LATEST);
    setLatestResources(latest);
  };

  return (
    <div className={classes.root}>
      <Carousel resources={latestResources} />

      <PaddedContainer>
        <Box py={8}>
          <Typography gutterBottom align="justify">
            {textFragments[0]}
          </Typography>

          <Box pt={8}>
            <Gallery images={gallerySections[0]} />
          </Box>
        </Box>
      </PaddedContainer>

      <Box display="flex" py={2} className={classes.highlight}>
        <Container maxWidth={'lg'}>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            flexWrap="wrap"
            my={5}
          >
            <Typography align="justify">
              {textFragments[2]}
            </Typography>
          </Box>
        </Container>
      </Box>

      <PaddedContainer>
        <Box py={8}>
          <Typography gutterBottom align="justify">
            {textFragments[1]}
          </Typography>
        </Box>
      </PaddedContainer>

      <PaddedContainer>
        <Gallery images={gallerySections[1]} />
      </PaddedContainer>

      <PaddedContainer>
        <Box py={8}>
          <Typography gutterBottom align="justify">
            {textFragments[3]}
          </Typography>
        </Box>
      </PaddedContainer>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    "& p": {
      fontSize: "20px !important",
      [theme.breakpoints.down("sm")]: {
        fontSize: "16px !important",
      },
    },
  },
  highlight: {
    backgroundColor: theme.palette.primary.light,
    boxShadow: "16px 16px 30px 0 rgba(212, 218, 230, 0.7)",

    "& p": {
      fontSize: "22px !important",
      [theme.breakpoints.down("sm")]: {
        fontSize: "18px !important",
      },
    },
  },
  profileImage: {
    borderRadius: 10,
  },
}));
