import React from "react";
import {
  FormControl,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import CancelIcon from "@mui/icons-material/Cancel";

const SearchInput = ({ value, setValue, resetField }) => {

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  return (
    <FormControl fullWidth variant="standard">
      <InputLabel>Caută...</InputLabel>
      <Input
        autoFocus
        value={value}
        onChange={handleChange}
        endAdornment={
          <InputAdornment position="start">
            {value?.length > 0 ? (
              <IconButton onClick={resetField}>
                <CancelIcon />
              </IconButton>
            ) : (
              <SearchIcon />
            )}
          </InputAdornment>
        }
      />
    </FormControl>
  );
};

export default SearchInput;
