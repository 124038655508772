import React from "react";
import { Drawer, Button } from "@mui/material";
import { makeStyles } from "@mui/styles";

import SearchIcon from "@mui/icons-material/Search";
import SearchPanel from "../SearchPanel";

export default function InteractiveSearch() {
  const [isOpen, toggleIsOpen] = React.useState(false);

  const classes = useStyles();

  const toggleDrawer = (event, value) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    toggleIsOpen(value);
  };

  return (
    <>
      <Button
        onClick={(event) => toggleDrawer(event, true)}
        className={classes.searchButton}
        variant="contained"
        size="large"
      >
        <SearchIcon className={classes.searchIcon} />
      </Button>

      <Drawer
        anchor="right"
        open={isOpen}
        onClose={(event) => toggleDrawer(event, false)}
        onOpen={(event) => toggleDrawer(event, true)}
        keepMounted
      >
        <SearchPanel toggleDrawer={toggleDrawer} />
      </Drawer>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  searchButton: {
    backgroundColor: `${theme.palette.common.carouselBtnBck} !important`,
    boxShadow: 'none !important',
  },
  searchIcon: {
    color: `${theme.palette.primary.dark} !important`,
    fontWeight: 'bold',
  },
}));
