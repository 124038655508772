import { Box, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { RESPONSE } from "../../../../api/responses";
import { ROUTES } from "../../../../types/enum";
import SearchResult from "../SearchResult";

const SearchResults = ({ results, handleCloseDrawer }) => (
  <Box py={6}>
    <Typography variant="subtitle" paragraph gutterBottom>
      <b>
        {(results.articles && results.articles.length > 0) ||
        (results.videos && results.videos.length > 0) ? (
          "Rezultatele căutării"
        ) : (
          <Box py={14}>{RESPONSE.NO_RESULTS}</Box>
        )}
      </b>
    </Typography>

    <Box py={2}>
      {results.articles?.length > 0 && (
        <Typography variant="subtitle" gutterBottom>
          Articole:
        </Typography>
      )}
      {results.articles?.map(({ title, id, paragraphs }) => (
        <Link to={`${ROUTES.ARTICLES}/${id}`} onClick={handleCloseDrawer}>
          <SearchResult
            title={title}
            text={paragraphs.reduce(
              (acc, paragraph) => acc + " " + paragraph,
              ""
            )}
            variant="outlined"
            color="primary"
            hasReadMore
          />
        </Link>
      ))}
    </Box>

    <Box py={2}>
      {results.videos?.length > 0 && (
        <Typography variant="subtitle" gutterBottom>
          Episoade din <i>Pastila de Urologie</i> :
        </Typography>
      )}
      {results.videos?.map(({ title, number }) => (
        <Link to={`${ROUTES.VIDEOS}/${number}`} onClick={handleCloseDrawer}>
          <SearchResult
            title={"Episodul " + number}
            text={title}
            variant="outlined"
            color="primary"
          />
        </Link>
      ))}
    </Box>
  </Box>
);

export default SearchResults;
