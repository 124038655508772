import React from "react";
import { Button, Grid, TextField, Typography } from "@mui/material";

// Container
import { PaddedContainer } from "../../common/layout/containers";

export default function Login() {
  return (
    <PaddedContainer maxWidth="sm">
      <br />
      <Typography variant="h4" gutterBottom align="center">
        Login
      </Typography>
      <hr />
      <br />
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <TextField
            variant="filled"
            required
            id="outlined-required"
            label="Email"
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            variant="filled"
            id="outlined-password-input"
            label="Password"
            type="password"
            autoComplete="current-password"
            fullWidth
          />
        </Grid>

        <Grid item xs={12}>
          <Button variant="contained" fullWidth>Login</Button>
        </Grid>
      </Grid>
    </PaddedContainer>
  );
}
