const baseUrl = {
  dev: "http://localhost:80/",
  prod: "/",
};

const useBaseUrl = baseUrl.prod;

export const API_ROUTES = {
  REVIEWS: `${useBaseUrl}api/reviews`,
  ARTICLES: `${useBaseUrl}api/articles`,
  VIDEOS: `${useBaseUrl}api/videos`,
  LATEST: `${useBaseUrl}api/resources/latest`,
  ALL: `${useBaseUrl}api/resources/all`,
  CRUD: {
    DELETE_VIDEO: `${useBaseUrl}api/videos/delete`,
    ADD_VIDEO: `${useBaseUrl}api/videos/add`,
    EDIT_VIDEO: `${useBaseUrl}api/videos/edit`,
    DELETE_ARTICLE: `${useBaseUrl}api/articles/delete`,
    ADD_ARTICLE: `${useBaseUrl}api/articles/add`,
    EDIT_ARTICLE: `${useBaseUrl}api/articles/edit`,
  }
};
