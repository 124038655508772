import React, { useState } from "react";
import {
  Typography,
  Grid,
  InputLabel,
  FormControl,
  Select,
  MenuItem,
  Checkbox,
  Input,
  ListItemText,
  Switch,
  Box,
  Button,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import CancelIcon from "@mui/icons-material/Cancel";

import { PaddedContainer } from "../../common/layout/containers";
import { galleryItems } from "../../data-source/gallery-page-data";
import GalleryTimeline from "./components/GalleryTimeline";
import GalleryGrid from "./components/GalleryGrid";

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 230,
    },
  },
};

export default function GalleryPage() {
  const typeOptions = ["imagine", "videoclip"];

  const [types, setTypes] = useState([]);
  const [isTimeline, toggleIsTimeline] = useState(true);

  const classes = useStyles();

  const handleTypeChange = (event) => {
    const {
      target: { value },
    } = event;
    const types = typeof value === "string" ? value.split(",") : value;
    setTypes(types);
  };

  return (
    <PaddedContainer>
      <Typography variant="h6">Galerie</Typography>
      <hr />

      <Grid
        container
        spacing={3}
        justifyContent="flex-end"
        className={classes.container}
      >
        <Grid item xs={12} sm={6} md={9} className={classes.toggleItem}>
          <Typography
            onClick={() => toggleIsTimeline(false)}
            className={`${classes.label} ${
              isTimeline ? "" : classes.underlined
            }`}
          >
            Librărie
          </Typography>
          <Switch
            checked={isTimeline}
            onChange={() => toggleIsTimeline(!isTimeline)}
          />
          <Typography
            onClick={() => toggleIsTimeline(true)}
            className={`${classes.label} ${
              isTimeline ? classes.underlined : ""
            }`}
          >
            Cronologie
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <FormControl fullWidth>
            <InputLabel
              className={classes.multipleSelectLabel}
              id="multiple-checkbox-label"
            >
              Arată doar postările de tip...
            </InputLabel>
            <Select
              labelId="multiple-checkbox-label"
              id="multiple-checkbox"
              multiple
              value={types}
              onChange={typeOptions?.length > 0 && handleTypeChange}
              input={<Input label="Tip" />}
              renderValue={(selected) => selected.join(", ")}
              classes={{ icon: classes.multipleSelectIcon }}
              MenuProps={MenuProps}
            >
              {typeOptions?.map((type) => (
                <MenuItem key={type} value={type}>
                  <Checkbox
                    checked={types?.some(
                      (selectedType) => selectedType === type
                    )}
                  />
                  <ListItemText primary={type} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>

      <Grid container justifyContent="flex-end" className={classes.container}>
        {types.length > 0 && (
          <Button
            onClick={() => setTypes([])}
            startIcon={<CancelIcon color="primary" />}
            className={classes.clearButton}
          >
            Sterge filtrele
          </Button>
        )}
      </Grid>

      <Box pb={10}>
        {isTimeline ? (
          <GalleryTimeline
            galleryItems={galleryItems}
            filterTypes={types}
            classes={classes}
          />
        ) : (
          <GalleryGrid
            galleryItems={galleryItems}
            filterTypes={types}
            classes={classes}
          />
        )}
      </Box>
    </PaddedContainer>
  );
}

const useStyles = makeStyles((theme) => ({
  toggleItem: {
    display: "flex",
    alignItems: "center",
  },
  label: {
    marginRight: theme.spacing(1),
  },
  underlined: {
    borderBottom: `1px solid ${theme.palette.primary.darkText}`,
    color: theme.palette.primary.darkText,
    fontWeight: "bold !important",
  },
  borderRight: {
    borderRight: `2px solid ${theme.palette.primary.main}`,
    paddingRight: theme.spacing(4),
    position: "relative",
    width: "100%",
  },
  borderLeft: {
    borderLeft: `2px solid ${theme.palette.primary.main}`,
    paddingLeft: theme.spacing(4),
    position: "relative",
    width: "100%",
  },
  paddingLeft: {
    paddingLeft: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  dot: {
    position: "absolute",
    height: 10,
    width: 10,
    borderRadius: "50%",
    backgroundColor: theme.palette.primary.main,
    top: "50%",
    left: "calc(100% - 4px)",
  },
  mobileDot: {
    position: "absolute",
    height: 10,
    width: 10,
    borderRadius: "50%",
    backgroundColor: theme.palette.primary.main,
    top: "50%",
    left: "-6px",
  },
  readMore: {
    paddingBottom: theme.spacing(2),
    fontWeight: "bold",
    whiteSpace: "nowrap",
    fontSize: "16px",
  },
  container: {
    paddingTop: theme.spacing(3),

    [theme.breakpoints.down("sm")]: {
      padding: `${theme.spacing(3)} 0`,
    },
  },
  multipleSelectLabel: {
    marginLeft: -14,
  },
  multipleSelectIcon: {
    right: "0 !important",
  },
  clearButton: {
    color: `${theme.palette.primary.dark} !important`,
  },
  gridItem: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
  },
  gridImage: {
    objectFit: "cover",
    objectPosition: "center",
    width: "auto",
    height: 250,
    [theme.breakpoints.down("sm")]: {
      height: 330,
    },
  },
}));
