import React from "react";
import { Typography, Box, Stack } from "@mui/material";

import CustomChip from "../../common/components/data-display/CustomChip";
import { Link } from "react-router-dom";
import { ROUTES } from "../../../types/enum";

export default function TagsSection({ tags }) {
  return (
    <Box py={2}>
      <hr />
      {tags?.length > 0 && (
        <>
          <Typography variant="body1" align="right" gutterBottom>
            Acest articol face parte din categoriile:{" "}
          </Typography>

          <Stack direction="row" spacing={1} justifyContent="flex-end">
            {tags?.map((tag) => (
              <Link to={{pathname: ROUTES.ARTICLES, search: `?tags=${tag}`}}>
                <CustomChip label={tag} variant="outlined" color="primary" />
              </Link>
            ))}
          </Stack>
        </>
      )}
    </Box>
  );
}
