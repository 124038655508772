import { Box } from "@mui/system";
import ReactLoader from "react-loader-spinner";
import palette from "../../../../assets/theme/palette";

import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { RESPONSE } from "../../../../api/responses";

const Loader = () => (
  <Box display="flex" height="35vh" alignItems={"center"}>
    <ReactLoader
      type="ThreeDots"
      color={palette.primary.dark}
      height={50}
      width={50}
    />
    <Box pl={2}>{RESPONSE.LOADING}</Box>
  </Box>
);

export default Loader;
