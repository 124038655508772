import { createTheme } from '@mui/material/styles'
import palette from './palette'

const theme = createTheme({
  palette,
  typography: {
    fontFamily: 'Montserrat, sans-serif'
  },
  overrides: {
    MuiButton: {
      root: {
        textTransform: 'none'
      },
      containedPrimary: {
        color: 'white'
      },
    }
  },
  borderRadius: '5px',
  fonts: {
    article: 'Lato, sans-serif'
  }
})

export default theme
