import React, { useEffect, useState } from "react";
import {
  Box,
  capitalize,
  IconButton,
  Drawer,
  Toolbar,
  useMediaQuery,
} from "@mui/material";
import { makeStyles, useTheme } from "@mui/styles";
import AppBar from "@mui/material/AppBar";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";

// Components
import NavScrollableTabs from "../../common/components/navigation/NavScrollableTabs";
import Logo from "../../common/components/Logo";

// Others
import { ALL_ROUTES } from "../constants/routes";
import { ADMIN_PREFIX } from "../../../types/enum";

const AdminTopBarNavigation = () => {
  const classes = useStyles();
  const [isOpen, toggleIsOpen] = useState(false);
  const [activeLink, setActiveLink] = useState("");

  const { pathname } = useLocation();

  const theme = useTheme();
  const matchesDesktop = useMediaQuery(theme.breakpoints.up("md"));

  const routesToTabsMap = ALL_ROUTES.filter((tab) => !tab.hide).map(
    ({ name, link, id }) => ({
      name,
      link,
      id,
      html: <div className={classes.link}>{capitalize(name)}</div>,
    })
  );

  const isActiveLink = (pathname, id) => {
    console.log(pathname, id);
    pathname.split(ADMIN_PREFIX).some((token) => {
      console.log(token, id);
      return token?.toLowerCase() === id.toLowerCase();
    });
  };

  useEffect(() => {
    console.log(pathname);
    window.scrollTo(0, 0);

    const actiev = ALL_ROUTES.find(({ id }) => isActiveLink(pathname, id));
    setActiveLink('aici', actiev);
  }, [pathname]);

  const onTabClick = () => toggleIsOpen(false);

  const desktopTopNavigation = () => (
    <AppBar position="sticky" className={classes.appbar}>
      <Box className={classes.root}>
        {
          <Link to={"/"}>
            <div className={classes.logo}>
              <Logo />
              <div className={classes.title}>Dr. Mihai Vârlan</div>
            </div>
          </Link>
        }

        <Box display={"flex"}>
          <NavScrollableTabs
            darkTheme
            tabs={routesToTabsMap}
            initialValue={activeLink || routesToTabsMap[0]}
          />
        </Box>
      </Box>
    </AppBar>
  );

  const mobileTopNavigation = () => (
    <>
      <AppBar position="sticky" className={classes.appbar}>
        <Toolbar className={classes.toolbar}>
          {
            <div className={classes.logo}>
              <Logo />
              <div className={classes.title}>Dr. Mihai Vârlan</div>
            </div>
          }

          <Box display={"flex"}>
            <IconButton color="inherit" onClick={() => toggleIsOpen(true)}>
              <MenuIcon />
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>
      <Drawer
        anchor={"top"}
        open={isOpen}
        onClose={() => toggleIsOpen(false)}
        className={classes.drawer}
        classes={{
          paper: classes.mobileMenu,
        }}
      >
        <IconButton color="primary" onClick={() => toggleIsOpen(false)}>
          <CloseIcon />
        </IconButton>
        <NavScrollableTabs
          tabs={routesToTabsMap}
          initialValue={activeLink || routesToTabsMap[0]}
          onTabClickCallback={onTabClick}
        />
      </Drawer>
    </>
  );

  return (
    <>
      {matchesDesktop
        ? desktopTopNavigation()
        : mobileTopNavigation()}
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  appbar: {
    backgroundColor: `${theme.palette.primary.main} !important`,
    height: 74,
    display: "flex",
    justifyContent: "center",
    "& *": {
      transition: "all 0.3s ease-in-out",
    },
  },
  toolbar: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  drawer: {
    backgroundColor: theme.palette.primary.light,
  },
  root: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    padding: theme.spacing(2),
    alignItems: "center",
    backgroundColor: `${theme.palette.primary.main} !important`,
    zIndex: 2000,
  },
  logo: {
    marginRight: theme.spacing(2),
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  title: {
    fontFamily: "'Italianno', cursive",
    fontSize: 37,
    lineHeight: "10px",
    color: theme.palette.common.darkBlue,
    whiteSpace: "nowrap",

    [theme.breakpoints.down("md")]: {
      fontSize: "2rem",
      lineHeight: "2rem",
    },
  },
  link: {
    padding: `0 ${theme.spacing(1)}`,
    color: theme.palette.common.darkBlue,
    textDecoration: "none",
  },
  mobileMenu: {
    paddingTop: theme.spacing(3),
  },
}));

export default AdminTopBarNavigation;
