export const gallerySections = [
    [
        {
            original:"./media/carousel/5.jpg",
            thumbnail:"./media/carousel/t5.jpg",
            width:"1024",
            height:"768",
        },
        {
            original:"./media/carousel/3.jpg",
            thumbnail:"./media/carousel/t3.jpg",
            width:"1024",
            height:"768",
        },
        {
            original:"./media/carousel/4.jpg",
            thumbnail:"./media/carousel/t4.jpg",
            width:"1024",
            height:"768",
        },
        {
            original:"./media/carousel/6.jpg",
            thumbnail:"./media/carousel/t6.jpg",
            width:"1024",
            height:"768",
        },
        {
            original: "./media/carousel/7.jpg",
            thumbnail: "./media/carousel/t7.jpg",
            width: "1024",
            height: "768",
        },
    ],
    [
        {
            original:"./media/carousel/12.jpg",
            thumbnail:"./media/carousel/t12.jpg",
            width:"768",
            height:"768",
        },
        {
            original: "./media/carousel/1.jpg",
            thumbnail: "./media/carousel/t1.jpg",
            width: "1024",
            height: "650",
        },
        {
            original:"./media/carousel/8.jpg",
            thumbnail:"./media/carousel/t8.jpg",
            width:"570",
            height:"768",
        },
        {
            original:"./media/carousel/9.jpg",
            thumbnail:"./media/carousel/t9.jpg",
            width:"1024",
            height:"768",
        },
        {
            original:"./media/carousel/13.jpg",
            thumbnail:"./media/carousel/t13.jpg",
            width:"768",
            height:"768",
        },
    ],
]