import React from "react";
import { Link } from "react-router-dom";
import { Box, Grid, Typography, useMediaQuery } from "@mui/material";
import StarIcon from "@mui/icons-material/Star";
import { format } from "date-fns";
import { DATE_FORMAT } from "../../../util/date-utils";
import { makeStyles, useTheme } from "@mui/styles";
import { ROUTES } from "../../../../types/enum";

// Components
import Card from "./Card";
import { mergeParagraphs } from "../../../util/articles-utils";

export default function ArticleHero({ id, title, date, paragraphs, src }) {
  const classes = useStyles();

  const theme = useTheme();
  const matchesDesktop = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <Box pt={1}>
      <Box display="flex" alignItems="center" mb={2}>
        <StarIcon color="primary" />
        <Typography variant="h6" className={classes.heading} fontWeight={"bold"}>În evidență</Typography>
      </Box>
      <Card hero className="article-card article-card-featured">
        <Link to={`${ROUTES.ARTICLES}/${id}`}>
          <Box
            display="flex"
            p={2}
            flexDirection="column"
            justifyContent={"center"}
          >
            <h3>{title}</h3>

            <Grid
              container
              py={3}
              display={"flex"}
              flexDirection={matchesDesktop ? "row-reverse" : "row"}
              spacing={3}
            >
              {src && (
                <Grid item md="12" lg="3">
                  <img
                    src={src[0]}
                    alt="article hero"
                    className={classes.image}
                  />
                </Grid>
              )}

              <Grid item md="12" lg={src ? "9" : "12"}>
                <p className={classes.articleText}>
                  {mergeParagraphs(paragraphs).slice(
                    0,
                    matchesDesktop ? 670 : 400
                  )}{" "}
                  <span className="read-more">[...Citeste mai departe]</span>
                </p>
              </Grid>
            </Grid>

            <div>{format(new Date(date), DATE_FORMAT)}</div>
          </Box>
        </Link>
      </Card>
    </Box>
  );
}

const useStyles = makeStyles((theme) => ({
  articleText: {
    fontSize: "18px",
    fontFamily: `${theme.fonts.article} !important`,
    [theme.breakpoints.down("md")]: {
      fontSize: "16px",
    },
  },
  image: {
    maxWidth: "100%",
    maxHeight: "100%",
  },
  heading: {
    color: `${theme.palette.primary.darkText} !important`,
  },
}));
