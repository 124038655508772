import React, { useEffect, useState } from "react";
import { Grid, Typography, Box } from "@mui/material";

// Components
import { PaddedContainer } from "../../common/layout/containers";
import VideoThumbnail from "../../common/components/data-display/VideoThumbnail";


// data
import Api from "../../../api/Api";
import { API_ROUTES } from "../../../api/api-routes";
import { RESPONSE } from "../../../api/responses";
export default function VideoGalleryPage() {
  const [videoLinks, setVideoLinks] = useState([]);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    const links = await Api.get(API_ROUTES.VIDEOS);
    setVideoLinks(links || []);
  };
  return (
    <PaddedContainer>
      <Typography variant="h6" gutterBottom>
        Pastila de urologie
      </Typography>
      <hr />

      <Grid container spacing={3} alignContent="center" alignItems="center">
        {videoLinks.map((video, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <VideoThumbnail videoNbr={video.number} title={video.title} />
          </Grid>
        ))}
      </Grid>

      {videoLinks.length === 0 && (
        <Box pt={4}>
          <Typography>{RESPONSE.NO_VIDEOS}</Typography>
        </Box>
      )}
    </PaddedContainer>
  );
}
