export const interviewQuestions = [
  {
    subtitle: 'Maria Paşcalău: "Când ați știut că vreți să deveniți medic? Ați avut vreo motivație specială?"',
    text: 'Am ştiut că vreau să fac medicină de la o vârstă foarte fragedă, aş spune… Aveam vreo 11-12 ani, oricum, prin clasa a VI-a a ciclului gimnazial. Eu cred foarte tare în ideea de vocaţie, cred că meseria alege omul şi nu invers. Desigur că asta înseamnă ca omul, respectiv oamenii din jur - părinţi, bunici sau oricine se ocupă la un anumit moment de educaţia unui copil - să aibă ochii sufletului larg deschişi pentru a putea înţelege încotro se îndreaptă vocaţionalul fiecărui copil, al fiecărui vlăstar uman. Unii au vocaţie spre muzică, artă, alţii dimpotrivă, spre inginerie, matematică, iar alţii spre medicină, preoţie şi aşa mai departe. Cât mă priveşte pe mine, sunt cu siguranţă un caz fericit, pentru că părinţii mei au fost şi sunt nişte oameni luminaţi, care au înţeles că aripile unui copil trebuiesc a fi desfăcute, iar nu încătuşate. Au contat probabil şi poveştile bunicii mele, care a fost asistentă medicală şi care a fost realmente un exemplu de dedicaţie şi profesionalism, într-o vreme când medicina se făcea la capul bolnavului mai mult cu sufletul decât cu instrumentele. Din păcate, astăzi - şi o spun cu regret - mulţi medici sau asistente pierd din vedere latura spirituală a acestei meserii. Dacă sufletul e în suferinţă, corpul material nu are nicio şansă de vindecare reală.',
  },
  {
    subtitle: 'Maria Paşcalău: "Ați ales urologia. De ce nu altceva?"',
    text: 'Din nou, cred că urologia m-a ales pe mine. Aici am o poveste scurtă, dar care cred eu e definitorie pentru ceea ce am încercat să subliniez înainte. În anul V de facultate am fost selecţionat pe baza rezultatelor să particip la o bursă semestrială în Germania, la Klinikum Ingolstadt. Oraşul este micuţ, undeva la 120.000 de locuitori, însă e vestit pentru două lucruri. Este oraşul-mamă al concernului Audi şi are unul dintre cele mai mari spitale din Bavaria, o zonă foarte dezvoltată a ţării. Odată ajuns aici, urma să facem stagii pe diferite specialităţi. Aleator sau nu, prima mea repartiţie a fost în clinica de urologie. Ca proaspăt sosit, desigur că eram niţel buimac, mai ales că diferenţa între spitalele româneşti, cu care eram obişnuit de acasă, şi cel din Germania era realmente uriaşă. La un moment dat, apare un medic extrem de energic, aproape uşor zgomotos, care mă întreabă cine sunt şi ce fac acolo. După o scurtă explicaţie, îmi spune: Vino cu mine! Şi dus am fost din acel moment pe calea urologiei. Acel medic era şeful interimar al secţiei, dr. Schneider, care în limba germană înseamnă croitor - şi asta mi s-a părut de asemenea predestinat. Într-adevăr, medicul Schneider mi-a croit din acel moment drumul către urologie. Când am venit acasă, am ştiut că voi deveni urolog!',
  },
  {
    subtitle: 'Maria Paşcalău: "Cu ce gânduri v-ați început parcursul în medicină și câte dintre ele au prins contur?"',
    text: 'Pentru un tânăr aflat la începutul formării profesionale, visurile şi planurile coincid întru totul… Apoi, pe parcurs, ele fie se cizelează, fie se spulberă, datorită contactului cu realitatea din teren. Eu, când am dat la medicină, am ştiut că vreau să devin chirurg. Practic, doream să fac chirurgie din momentul în care am decis să fiu medic. Sigur că la început, influenţat de lecturile despre Christian Barnard, mi-am dorit să devin chirurg cardiovascular. Am realizat însă destul de repede, văzând că la examenul naţional de rezidenţiat se scot unu - două locuri o dată la doi - trei ani, că aceste posturi erau dedicate. În acei ani, Europa nu era deschisă pentru români, singura şansă fiind să promovezi examenele de recunoaştere a diplomei în SUA, iar mai apoi să încerci să prinzi un loc de rezidenţiat american. Foarte tânăr fiind, am realizat totuşi că era un deziderat foarte greu de atins pentru un român şi atunci am încercat să mă reorientez către o altă ramură chirurgicală. După experienţa din Germania însă, nu am mai avut nicio undă de regret. Realizasem că totul nu fusese decât parte a drumului de a deveni urolog, adică parte a destinului meu.',
  },
  {
    subtitle: 'Maria Paşcalău: "Care sunt cele mai frecvente probleme de sănătate la nivelul sistemului urinar cu care se confruntă românii, bărbați și femei?"',
    text: 'Problemele urologice cu care se confruntă românii sunt probleme comune cu care se confruntă în principiu toţi europenii. Aş spune că există două direcţii mari: problema litiazei renale (adică a pietrelor), respectiv problema afecţiunilor oncologice ale tractului urinar. Din păcate, spre deosebire de ţările vestice, Estul Europei şi România, implicit, suferă mai mult în ambele direcţii. Explicaţia este una simplă, dar dureroasă şi incumbă de asemenea două aspecte: pe de o parte, sistemul medical românesc care este unul înţepenit, anacronic şi greoi, neschimbat de la momentul 1989, nu este capabil să ofere pacientului român variante de depistare precoce a bolii (atât a celei litiazice, cât şi a celor neoplazice), în timp ce accesibilitatea la metode moderne de tratament este mult îngreunată de subfinanţarea grosieră a sistemului de sănătate. În unele locuri s-au produs schimbări, dar şi acolo subfinanţarea îşi spune cuvântul. Degeaba cumperi ca spital un aparat performant, dacă apoi nu ai bani pentru consumabile, reparaţii sau mentenanţă. A doua problemă este cea a educaţiei sanitare extrem de precare în rândul populaţiei generale. Aici discuţia este una mult mai amplă, dar cred că un rol important revine medicinii primare la nivelul medicilor de familie, şi ei din păcate blocaţi de multe ori de un sistem neprietenos şi învechit.',
  },
  {
    subtitle: 'Maria Paşcalău: "Ce metode de screening considerați cele mai potrivite pentru depistarea acestor probleme?"',
    text: 'Metodele de screening sunt statuate şi standardizate, dar, aşa cum spuneam, datorită sistemului, de multe ori, inaccesibile pentru pacient. Vă dau un exemplu: screening-ul pentru depistarea precoce a cancerului de prostată presupune, printre altele, efectuarea unei analize serologice numită antigen specific prostatic PSA. Există în acest moment, fără a da nume, spitale mari în România care nu efectuează această analiză în cadrul spitalizării. Gândiţi-vă că ea costă în mediul privat undeva la 50 RON şi totuşi nu se efectuează de rutină în toate unităţile spitaliceşti mari din ţară.',
  },
  {
    subtitle: 'Maria Paşcalău: "Este gradul de responsabilizare cu privire la propria sănătate diferit în România comparativ cu restul statelor europene?"',
    text: 'Cu siguranţă că gradul de responsabilizare cu privire la propria sănătate este complet diferit. În ţările vestice, spre care privim cu toţii, sistemul este perfect creat pentru a veni în sprijinul cetăţeanului. Lăsând la o parte educaţia sanitară, care în Vest se face începând de pe băncile şcolii primare, sistemul este astfel conceput încât să ajute cetăţeanul, chiar dacă la un anumit moment, din varii motive care pot ţine inclusiv de un intelect mai subliminal, acesta are tendinţa să se încurce singur. De exemplu, există state în care, dacă nu respecţi programarea la analizele uzuale recomandate, poţi avea surpriza să pierzi beneficiile asigurării de sănătate. Spun lucrul acesta în cunoştinţă de cauză. Pe parcursul desfăşurării mele profesionale, am lucrat sau am beneficiat de burse de studiu în Germania, Italia, Israel, Franţa astfel încât am intrat în contact cu numeroase sisteme performante de sănătate.',
  },
  {
    subtitle: 'Maria Paşcalău: "Care este profilul pacientului ideal? Care sunt regulile de igienă şi conduită medicală pe care ar trebui să le respecte acesta?"',
    text: 'Profilul pacientului ideal este profilul pacientului educat. Dar, la fel ca în viaţă, a creşte un pacient educat echivalează cu a creşte un copil educat. În primul rând, mediul familial trebuie să fie unul favorabil, fără certuri, violenţă sau gesturi necuviincioase (acesta este sistemul de sănătate). Apoi, copilul trebuie în permanenţă încurajat, sprijinit în dezvoltare (acestea sunt sistemele de screening, asigurări de sănătate corecte). Atunci când copilul are tendinţa de a apuca pe o cale greşită, familia trebuie să intervină cu corecţii (ce subliniam anterior vizavi de riscul de a pierde beneficiile asigurării de sănătate). În condiţii de restrişte, familia pune la bătaie toate resursele pentru salvarea copilului. La fel şi cu un sistem corect şi performant de sănătate, în cazul unui pacient educat.',
  },

  {
    subtitle: 'Maria Paşcalău: "Ce părere aveţi despre pacienţii care apelează la Internet pentru a afla diverse informaţii despre afecţiunea / tratamentul lor?"',
    text: 'Internetul a devenit cu siguranţă o parte a vieţii contemporane, la fel ca şi smartphone-ul. Această realitate nu poate fi neglijată. Orice lucru, făcut cu măsură şi responsabilitate, are beneficiile lui. Dar trebuie să ne gândim că viaţa se desfăşura absolut normal şi acum 30-40 de ani, când toate aceste lucruri (Internetul, smartphone-ul) erau nişte elemente de filme SF. Acum au devenit o rutină. Asta nu înseamnă că, dacă am învăţat să folosim Internet-ul (ceea ce între noi fie vorba, nu e chiar o chestie pentru genii), putem automat să substituim ani grei de şcoală. Sigur că, urmând un tutorial pe YouTube, poţi învăţa rapid să faci un nod la cravată sau să prepari o prajitură. Cu siguranţă însă nu vei obţine o diplomă în fizica cuantică sau medicină. Cred că domeniile complexe presupun studii aprofundate şi trebuiesc a fi lăsate exclusiv în seama specialiştilor. Într-un cuvânt, autodiagnosticarea pe Internet sau, şi mai rău, autotratarea după tutoriale, poate fi realmente periculoasă. Dacă discutăm despre o informare, un research vizavi de o procedură, un anumit tratament, dacă ea se face pe pagini oficiale, urmând sfaturile unor specialişti certificaţi în domeniu, sigur că asta ţine deja de ceea ce numeam mai sus educaţia medicală a fiecăruia dintre noi.',
  },
  {
    subtitle: 'Maria Paşcalău: "Care este cea mai frumoasă amintire legată de rezolvarea unui caz medical?"',
    text: 'Toate cazurile rezolvate cu succes, toţi pacienţii vindecaţi reprezintă o amintire de neşters pentru un medic care şi-a urmat într-adevăr vocaţia. Din această perspectivă, profesia de medic este cu siguranţă cea mai frumoasă din lume, pentru că este încărcată de amintiri frumoase, de oameni care te privesc cu recunoştinţă, care îţi strâng mâna cu lacrimi în ochi. Alexis Carell spunea că rugăciunea altuia te ajută enorm, chiar dacă tu nu crezi. Medicul este o fiinţă a cărui viaţă se desfăşoară într-un permanent risc, dar ocrotită de rugăciunile şi energia pozitivă a fiecărui pacient salvat. Aceasta aproape că îţi conferă o energie nepământeană, care te face să treci peste toate greutăţile.',
  },
  {
    subtitle: 'Maria Paşcalău: "Ce hobby-uri consideraţi că sunt benefice pentru un medic, dar şi utile în relaxarea şi detaşarea de zona medicală? Cum vă petreceţi timpul liber?"',
    text: 'Fiecare îşi găseşte zona lui de confort, de relaxare. Unii preferă să înoate, alţii să joace tenis, alţii să petreacă timp cu prietenii. Atâta timp cât se realizează mult necesara deconectare, este absolut OK. Cât mă priveşte, îmi place foarte mult să călătoresc, să cunosc culturi şi oameni noi, tradiţii specifice fiecărui loc în parte. Pe de altă parte, relaxarea de zi cu zi cred că ţi-o dă liniştea căminului tău, preferabil înconjurat de pădure, păsări, aer curat şi un pahar de vin bun.',
  },
  {
    subtitle: 'Maria Paşcalău: "Ce sfaturi aţi putea oferi unui medic aflat la început de drum?"',
    text: 'Un medic la început de drum trebuie să ştie exact ce vrea! Un tânăr medic fără idealuri, ţeluri şi planuri este cu siguranţă, o şansă ratată. Pe lângă acestea, trebuie să fie înzestrat cu îndârjire, voinţă şi perseverenţă. Viaţa lui trebuie să fie organizată astfel încât cariera să fie o prioritate desăvârşită. Când eram rezident, aveam un salariu echivalent cu vreo 200 de euro. Preferam să mă duc în vacanţe mai ieftine pentru a putea strânge bani pentru cursuri. Sacrificiul trebuie să fie apanajul tânărului medic. Investiţia în el însuşi, în profesia şi profesionalizarea lui. Primul căruia trebuie să îi pese de educaţia lui trebuie să fie el însuşi. Apoi mai e cursa cu tine însuţi. Întotdeauna, tânărul medic trebuie să îşi dorească să fie mai bun decât ieri. O nereuşită profesională de moment nu trebuie să descurajeze, ci din contră, să motiveze. Nu există evoluţie fără eşec. Greşeala face parte din procesul de învăţare şi de înălţare profesională. Seriozitatea, decenţa şi moralitatea trebuie să îl însoţească din primul până în ultimul moment al carierei. Apoi el trebuie să fie atent la detalii şi aprofundare. Le spun aceasta în fiecare zi rezidenţilor care lucrează cu mine. În profesie, la fel ca şi în viaţă, detaliile fac diferenţa. Diferenţa între un medic mediocru şi unul bun. Diferenţa între un medic bun şi unul excepţional. Dacă aceste lucruri sunt îndeplinite, sentimentul datoriei împlinite, recunoştinţa semenilor tăi şi lacrimile lor de bucurie îţi vor umple viaţa. Iar o viaţă plină e o viaţă minunată!'
  },
];
