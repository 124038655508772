import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Grid,
  IconButton,
  Slide,
  Typography,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import Snackbar from "@mui/material/Snackbar";
import { makeStyles } from "@mui/styles";
import { Link } from "react-router-dom";

// Container
import { PaddedContainer } from "../../common/layout/containers";
import Api from "../../../api/Api";
import { API_ROUTES } from "../../../api/api-routes";
import { ADMIN_ROUTES } from "../../../types/enum";

export default function AdminVideosPage() {
  const [videoLinks, setVideoLinks] = useState([]);
  const [open, setIsOpen] = useState(false);
  const [serverResponse, setServerResponse] = useState(null);
  const classes = useStyles();

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    const links = await Api.get(API_ROUTES.VIDEOS);
    setVideoLinks(links || []);
  };

  const deleteItem = async (id) => {
    const { message } = await Api.post(API_ROUTES.CRUD.DELETE_VIDEO, { id });
    setIsOpen(true);
    setServerResponse(message);
    getData();
  };

  return (
    <PaddedContainer>
      <Typography variant="h6" gutterBottom>
        Toate episoadele
      </Typography>
      <hr />

      <Box textAlign={"right"}>
        <Link to={ADMIN_ROUTES.VIDEO}>
          <Button
            startIcon={<AddIcon />}
            aria-label="add"
            color="primary"
            variant="contained"
          >
            Adauga episod
          </Button>
        </Link>
      </Box>

      <Grid container pt={3}>
        {videoLinks.map((video) => (
          <Grid item container xs={12} md={12} className={classes.gridItem}>
            <Grid item xs={8} md={10} alignSelf="center">
              {video.title}
            </Grid>
            <Grid item xs={4} md={2} textAlign="right">
              <Link to={ADMIN_ROUTES.VIDEO + "/" + video.id}>
                <IconButton aria-label="edit" color="primary">
                  <EditIcon />
                </IconButton>
                <IconButton
                  aria-label="delete"
                  color="warning"
                  onClick={() => deleteItem(video.id)}
                >
                  <DeleteIcon />
                </IconButton>
              </Link>
            </Grid>
          </Grid>
        ))}
      </Grid>

      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={open}
        message={serverResponse}
        TransitionComponent={(props) => <Slide {...props} direction="down" />}
        autoHideDuration={2000}
        onClose={() => setIsOpen(false)}
        key={"topRight"}
      />
    </PaddedContainer>
  );
}

const useStyles = makeStyles((theme) => ({
  gridItem: {
    padding: theme.spacing(1),
    borderRadius: 5,

    "&:hover": {
      backgroundColor: "rgba(255, 255, 255, 0.2)",
    },
  },
}));
