import React from "react";
import { Button, Grid, Typography } from "@mui/material";

// Container
import { PaddedContainer } from "../../common/layout/containers";
import { Link } from "react-router-dom";
import { ROUTES } from "../constants/routes";

export default function AdminHomePage() {
  return (
    <PaddedContainer maxWidth="sm">
      <br />
      <Typography variant="h6" gutterBottom align="center">
        Cu ce doresti sa lucrezi azi?
      </Typography>
      <hr />
      <br />
      <Grid container spacing={4}>
       {
        ROUTES.map((route) => (
            <Grid item xs={12} md ={6}>
                <Link to={route.link}><Button variant="contained" fullWidth>{route.label}</Button></Link>
            </Grid>
        ))
       }
      </Grid>
    </PaddedContainer>
  );
}
