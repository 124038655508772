import React, { useEffect } from "react";
import Carousel from "react-bootstrap/Carousel";
import { makeStyles } from "@mui/styles";
import { Box, Button, Typography } from "@mui/material";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { Link } from "react-router-dom";
import { ROUTES } from "../../../types/enum";

import HeroImage from "./HeroImage";

export default function HomePageCarousel({ resources }) {
  const classes = useStyles();

  const hasMultipleSlides = resources?.article || resources?.video;

  const imageSrcs = [
    "./media/home/AdobeStock_362725157.jpeg",
    "./media/profile-hero2.png",
    "./media/heromic-copy.jpg",
    "./media/home/pastila.jpg",
  ];

  const preloadImage = () => {
    imageSrcs.forEach((picture) => {
      const img = new Image();
      img.src = picture.fileName;
    });
  };

  useEffect(() => {
    preloadImage();
  });

  return (
    <Box className={classes.root}>
      <Carousel
        variant="dark"
        controls={!!hasMultipleSlides}
        indicators={!!hasMultipleSlides}
      >
        <Carousel.Item>
          <HeroImage />
        </Carousel.Item>
        {resources?.article && (
          <Carousel.Item>
            <div className={`${classes.item} ${classes.article}`} />
            <Carousel.Caption className={classes.caption}>
              <Link to={`${ROUTES.ARTICLES}/${resources.article?.id}`}>
                <Typography variant="h6" gutterbottom>
                  Articolul zilei
                </Typography>
                <Button
                  endIcon={<OpenInNewIcon fontSize="large" />}
                  color={"primary"}
                  className={classes.button}
                  size="large"
                  variant="outlined"
                >
                  {resources.article?.title}
                </Button>
              </Link>
            </Carousel.Caption>
          </Carousel.Item>
        )}
        {resources?.video && (
          <Carousel.Item>
            <div className={`${classes.item} ${classes.video}`} />
            <Carousel.Caption className={classes.caption}>
              <Link to={`${ROUTES.VIDEOS}/${resources.video?.number}`}>
                <Typography variant="h6" gutterbottom>
                  Ai vazut ultimul episod din <i>Pastila de Urologie</i>?
                </Typography>
                <Button
                  endIcon={<PlayCircleOutlineIcon fontSize="large" />}
                  color={"primary"}
                  className={classes.button}
                  size="large"
                  variant="outlined"
                >
                  {resources.video &&
                    "Episodul " +
                      resources.video.number +
                      ": " +
                      resources.video.title}
                </Button>
              </Link>
            </Carousel.Caption>
          </Carousel.Item>
        )}
      </Carousel>
    </Box>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: -120,
    boxShadow: "16px 16px 30px 0 rgba(212, 218, 230, 0.7)",
  },
  item: {
    height: 550,
    paddingTop: 120,
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-end",
    backgroundSize: "cover",

    [theme.breakpoints.down("sm")]: {
      height: 650,
    },
  },
  caption: {
    padding: `${theme.spacing(6)} ${theme.spacing(4)} !important`,
  },
  article: {
    background: "url(./media/home/AdobeStock_362725157.jpeg) no-repeat",
    backgroundPosition: "center",
    backgroundSize: "cover",
  },
  video: {
    background: "url(./media/home/pastila.jpg) no-repeat",
    backgroundPosition: "bottom center",
    backgroundSize: "cover",
    [theme.breakpoints.down("md")]: {
      backgroundPosition: "bottom right",
    },
  },
  button: {
    color: `${theme.palette.primary.dark} !important`,
    marginTop: `${theme.spacing(1)} !important`,
    backgroundColor: `${theme.palette.common.carouselBtnBck} !important`,

    textTransform: "none !important",
    textDecoration: "none !important",
    fontWeight: "600 !important",
    fontSize: "20px !important",
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px !important",
    },
  },
}));
