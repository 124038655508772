import React, { useEffect, useState, useCallback } from "react";
import { Box, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useParams } from "react-router-dom";
import { format } from "date-fns";

// Components
import { PaddedContainer } from "../../common/layout/containers";
// import ReadMoreSection from "./ReadMoreSection";
import BackNavigationButton from "../../common/components/navigation/BackNavigationButton";

//Api
import Api from "../../../api/Api";
import { API_ROUTES } from "../../../api/api-routes";
import { ROUTES } from "../../../types/enum";
import { DATE_FORMAT } from "../../util/date-utils";
import TagsSection from "./TagsSection";
import Paragraph from "../../common/components/data-display/Paragraph";
import VideoCard from "../../common/components/data-display/VideoCard";

export default function ArticleDetailPage() {
  const { id } = useParams();

  const [article, setArticle] = useState({});
  const classes = useStyles();

  const getData = useCallback(async () => {
    const article = await Api.get(API_ROUTES.ARTICLES + "/" + id);
    setArticle(article);
  }, [id]);

  const preloadImage = () => {
    ["./media/10.jpg"].forEach((picture) => {
      const img = new Image();
      img.src = picture.fileName;
    });
  };

  useEffect(() => {
    getData();
    preloadImage();
  }, [getData]);

  const { title, paragraphs, date, tags, src, embed } = article;

  return (
    <PaddedContainer>
      <BackNavigationButton
        route={ROUTES.ARTICLES}
        text={"Inapoi la articole"}
      />
      <Box className={classes.root}>
        <Box>
          <Typography
            variant="h6"
            gutterBottom
            align="center"
            className={classes.title}
          >
            {title}
          </Typography>
          <Typography gutterBottom align="center" variant="subtitle2">
            {date && format(new Date(date), DATE_FORMAT)}
          </Typography>
          <hr />
        </Box>

        <Box py={5} width={"100%"}>
          {src?.[0] && (
            <img src={src[0]} alt="imagine articol" className={classes.image} />
          )}
          {src?.[1] && (
            <img src={src[1]} alt="imagine articol" className={classes.image} />
          )}

          {paragraphs?.map((paragraph) => (
            <Paragraph text={paragraph} />
          ))}

          {embed && <VideoCard videoUrl={embed} noHover height="500" />}

          <TagsSection tags={tags} />
        </Box>
      </Box>
      {/* <ReadMoreSection articles={articles.slice(1, articles.length)} /> */}
      {/* // TODO: add read more to response, maybe related tags? */}
    </PaddedContainer>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  image: {
    width: "50%",
    float: "right",
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
    marginLeft: theme.spacing(4),
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
  title: {
    fontWeight: "bold !important",
    color: `${theme.palette.primary.darkText} !important`,
  },
}));
