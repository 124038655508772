import * as React from "react";
import VideoGalleryItem from "./components/GalleryVideo";
import CloseIcon from "@mui/icons-material/Close";
import { Dialog, IconButton, Box, Zoom, Typography } from "@mui/material";
import { format } from "date-fns";

import palette from "../../../assets/theme/palette";
import { DATE_FORMAT } from "../../util/date-utils";

const style = {
  backgroundColor: "#F8F9F9",
  py: 2,
  px: 5,
  overflow: "auto",
  height: "100%",
};

const closeBtn = {
  float: "right",
};

const imgContainer = {
  display: "flex",
  alignItems: "center !important",
  justifyContent: "center !important",
  img: {
    maxWidth: 700,
  },
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Zoom ref={ref} {...props} timeout={400} />;
});

const GalleryDetail = ({ mediaItem, open, handleClose, classes }) => {
  console.log(mediaItem);
  return (
    <Dialog
      fullScreen
      keepMounted
      open={open}
      onClose={handleClose}
      aria-labelledby="keep-mounted-modal-title"
      aria-describedby="keep-mounted-modal-description"
      TransitionComponent={Transition}
    >
      <Box sx={style}>
        <IconButton
          sx={closeBtn}
          aria-label="close modal"
          component="span"
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>

        <Box pt={2} sx={imgContainer} width={"100%"}>
          {mediaItem?.type === "imagine" ? (
            <img width={"80%"} src={mediaItem?.src} alt="alt" />
          ) : (
            <VideoGalleryItem
              src={mediaItem?.src}
              width={"700"}
              height={"500"}
            />
          )}
        </Box>

        <Box pt={2}>
          <Box
            display={"flex"}
            flexDirection={"column"}
            justifyContent="center"
            alignItems={"center"}
            height={"100%"}
            pb={2}
            color={palette.primary.darkText}
          >
            <div>{format(new Date(mediaItem.date), DATE_FORMAT)}</div>
            <Typography align="center">
              <b>{mediaItem.title}</b>
            </Typography>
          </Box>

          {mediaItem.description.split(". ").map((paragraph) => (
            <p>{paragraph}.</p>
          ))}
        </Box>
      </Box>
    </Dialog>
  );
};

export default GalleryDetail;
