import React, { useState, useCallback, useEffect, useRef } from "react";
import {
  Box,
  useMediaQuery,
  Typography,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import { useTheme } from "@mui/styles";
import Api from "../../../../api/Api";
import { API_ROUTES } from "../../../../api/api-routes";
import Loader from "../loaders/Loader";
import SearchResults from "./SearchResults";
import SearchInput from "./input/SearchInput";

const DEBOUNCE_MS = 1000;
const MIN_CHAR_SEARCH = 3;

export default function SearchPanel({ toggleDrawer = () => null }) {
  const [value, setValue] = useState("");
  const [loading, toggleIsLoading] = useState(false);
  const [results, setResults] = useState({
    articles: null,
    videos: null,
  });

  const theme = useTheme();
  const matchesDesktop = useMediaQuery(theme.breakpoints.up("md"));

  let debounceTimeout = useRef();

  const getData = async (keyword) => await Api.get(API_ROUTES.ALL, { keyword });

  const searchByKeyword = useCallback(
    async (filters) => {
      const data = await getData(filters);
      setResults({ articles: data?.articles, videos: data?.videos });
      toggleIsLoading(false);
    },
    [setResults]
  );

  useEffect(() => {
    if (value.length < MIN_CHAR_SEARCH) {
      toggleIsLoading(false);
      return;
    }

    toggleIsLoading(true);

    clearTimeout(debounceTimeout.current);
    debounceTimeout.current = setTimeout(
      () => searchByKeyword(value),
      DEBOUNCE_MS
    );

    return () => clearTimeout(debounceTimeout.current);
  }, [value, searchByKeyword]);

  const resetField = () => setValue("");

  const handleCloseDrawer = (event) => {
    resetField();
    toggleDrawer(event, false);
  };

  return (
    <Box width={matchesDesktop ? "60vw" : "100vw"} height={"100vh"} p={3}>
      <Box
        pb={1}
        display={"flex"}
        justifyContent={matchesDesktop ? "flex-end" : "center"}
      >
        <IconButton
          color="primary"
          onClick={handleCloseDrawer}
          style={matchesDesktop ? undefined : { width: "100%" }}
        >
          <CloseIcon />
        </IconButton>
      </Box>

      <SearchInput value={value} setValue={setValue} resetField={resetField} />

      {loading && <Loader />}

      {!loading && value.length >= MIN_CHAR_SEARCH && <SearchResults results={results} handleCloseDrawer={handleCloseDrawer} />}

      {!loading && value.length < MIN_CHAR_SEARCH && (
        <Box py={4}>
          <Typography>Introduceti cel puțin {MIN_CHAR_SEARCH} caractere pentru a începe căutarea.</Typography>
        </Box>
      )}
    </Box>
  );
}
