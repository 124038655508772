import React from "react";
import { Grid } from "@mui/material";
import { Gallery, Item } from "react-photoswipe-gallery";

// Components
import Card from "../data-display/Card";

import "photoswipe/dist/photoswipe.css";
import "photoswipe/dist/default-skin/default-skin.css";

import "./styles.scss";

export default function ImageGallery({ images }) {
  return (
    <Grid
      container
      className="gallery-container"
      spacing={4}
      alignItems="center"
      justifyContent="center"
      alignContent="center"
    >
      <Gallery>
        {images.map((image, index) => (
          <Item key={index} {...image} thumbnail={image.original}>
            {({ ref, open }) => (
              <Grid item xs={12} sm={4} md={2}>
                <Card>
                  <img
                    ref={ref}
                    onClick={open}
                    src={image.thumbnail}
                    alt="alt"
                    className="gallery-img"
                  />
                </Card>
              </Grid>
            )}
          </Item>
        ))}
      </Gallery>
    </Grid>
  );
}
