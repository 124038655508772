import React from "react";

// Components
import Card from "./Card";

export default function VideoCard({ videoUrl, noHover = false, height = "600" }) {
  return (
    <Card noHover={noHover}>
      <iframe
        src={videoUrl}
        height={height}
        title="YouTube video player"
        frameBorder="0"
        style={{ borderRadius: "5px" }}
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>
    </Card>
  );
}
