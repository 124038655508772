import axios from "axios";

const Api = {
  get: async (apiRoute, query = null) =>
    await axios
      .get(apiRoute, { params: query })
      .then(function ({ data }) {
        return data;
      })
      .catch(function (error) {
        console.warn(error);
      }),
  post: async (apiRoute, body) =>
    await axios
      .post(apiRoute, body)
      .then(function ({ data }) {
        return data;
      })
      .catch(function (error) {
        console.warn(error);
      }),
};

export default Api;
