import React from "react";
import { Grid, Slide } from "@mui/material";
import VideoGalleryItem from "./GalleryVideo";
import Card from "../../../common/components/data-display/Card";
import GalleryDetail from "../GalleryDetail";

const GalleryGrid = ({ galleryItems, filterTypes, classes }) => {
  const [selectedMediaItem, setSelectedMediaItem] = React.useState(null);
  const [openModal, setOpenModal] = React.useState(false);

  const handleOpen = (mediaItem) => {
    setSelectedMediaItem(mediaItem);
    setOpenModal(true);
  };

  const handleClose = () => {
    setSelectedMediaItem(null);
    setOpenModal(false);
  };

  return (
    <>
      <Slide direction="left" in={true} mountOnEnter unmountOnExit>
        <Grid container pt={6} spacing={2}>
          {galleryItems
            .filter(({ type }) =>
              filterTypes.length > 0
                ? filterTypes.some((selectedType) => selectedType === type)
                : true
            )
            .map((mediaItem) => (
              <Grid item md={3} sm={6} xs={12} className={classes.gridItem}>
                <Card onClick={() => handleOpen(mediaItem)}>
                  {mediaItem.type === "imagine" ? (
                    <img
                      alt="imagine galerie"
                      src={mediaItem.src}
                      className={`gallery-img ${classes.gridImage}`}
                    />
                  ) : (
                    <VideoGalleryItem src={mediaItem.src} noPlay />
                  )}
                </Card>
              </Grid>
            ))}
        </Grid>
      </Slide>

      {selectedMediaItem && (
        <GalleryDetail
          mediaItem={selectedMediaItem}
          handleClose={handleClose}
          open={openModal}
          classes={classes}
        />
      )}
    </>
  );
};

export default GalleryGrid;
