import React from "react";
import {
  FormControl,
  Input,
  InputLabel,
  MenuItem,
  Select,
  Grid,
  InputAdornment,
  Checkbox,
  ListItemText,
  IconButton,
  Typography,
  Button,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import SearchIcon from "@mui/icons-material/Search";
import { SORT_TYPE } from "./types";
import useArticles from "../hooks/use-articles";
import CancelIcon from "@mui/icons-material/Cancel";
import { initialFiltersState } from "../providers/reducers";
import { hasActiveFilters } from "../util/filters-util";
import { RESPONSE } from "../../../../api/responses";

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 230,
    },
  },
};

export default function Filters({ tags }) {
  const [{ filters }, { setFilters }] = useArticles();

  const classes = useStyles();

  const handleChange = (prop) => (event) => {
    setFilters({ ...filters, [prop]: event.target.value });
  };

  const handleTagChange = (event) => {
    const {
      target: { value },
    } = event;
    const tags = typeof value === "string" ? value.split(",") : value;
    setFilters({ ...filters, tags });
  };

  const resetField = (prop) => () => {
    setFilters({ ...filters, [prop]: initialFiltersState[prop] });
  };

  const resetAll = () => {
    setFilters({ ...initialFiltersState });
  };

  return (
    <>
      <Grid
        container
        spacing={3}
        justifyContent="flex-end"
        className={classes.container}
      >
        <Grid item xs={12} sm={6} md={3}>
          <FormControl fullWidth>
            <InputLabel>Sorteaza după</InputLabel>
            <Select
              label="Sorteaza după"
              value={filters.sortOrder}
              fullWidth
              onChange={handleChange("sortOrder")}
              variant="standard"
            >
              <MenuItem value={SORT_TYPE.DESC}>Dată, cele mai noi</MenuItem>
              <MenuItem value={SORT_TYPE.ASC}>Dată, cele mai vechi</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <FormControl fullWidth>
            <InputLabel
              className={classes.multipleSelectLabel}
              id="multiple-checkbox-label"
            >
              Categorii
            </InputLabel>
            <Select
              labelId="multiple-checkbox-label"
              id="multiple-checkbox"
              multiple
              value={filters.tags}
              onChange={tags?.length > 0 && handleTagChange}
              input={<Input label="Tag" />}
              renderValue={(selected) => selected.join(", ")}
              classes={{ icon: classes.multipleSelectIcon }}
              MenuProps={MenuProps}
            >
              {tags?.map((tag) => (
                <MenuItem key={tag} value={tag}>
                  <Checkbox
                    checked={filters.tags?.some(
                      (selectedTag) => selectedTag === tag
                    )}
                  />
                  <ListItemText primary={tag} />
                </MenuItem>
              ))}
              {tags?.length === 0 && (
                <Typography align="center">
                  {RESPONSE.NO_CATEGORIES}
                </Typography>
              )}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <FormControl fullWidth variant="standard">
            <InputLabel>Caută...</InputLabel>
            <Input
              value={filters.keyword}
              onChange={handleChange("keyword")}
              endAdornment={
                <InputAdornment position="start">
                  {filters.keyword?.length > 0 ? (
                    <IconButton onClick={resetField("keyword")}>
                      <CancelIcon />
                    </IconButton>
                  ) : (
                    <SearchIcon />
                  )}
                </InputAdornment>
              }
            />
          </FormControl>
        </Grid>
      </Grid>
      <Grid container justifyContent="flex-end" className={classes.container}>
        {hasActiveFilters(filters) && (
          <Button
            onClick={resetAll}
            startIcon={<CancelIcon color="primary" />}
            className={classes.clearButton}
          >
            Sterge filtrele
          </Button>
        )}
      </Grid>
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(3),

    [theme.breakpoints.down("sm")]: {
      padding: `${theme.spacing(3)} 0`,
    },
  },
  multipleSelectLabel: {
    marginLeft: -14,
  },
  multipleSelectIcon: {
    right: "0 !important",
  },
  clearButton: {
    color: `${theme.palette.primary.dark} !important`,
  },
}));
