export const galleryItems = [
  {
    src: "./media/gallery/img/poza_16.jpg",
    width: "1024",
    height: "768",
    type: 'imagine',
    description: `Am convingerea că în orice domeniu, în chirurgie cu atât mai mult cu cât este vorba despre sănătatea oamenilor, colaborarea și schimbul de experiență reprezintă cheia de boltă pentru dezvoltarea profesională. Cred că avem oricând și aproape în orice context de învățat unii de la alții. 
    Desigur că este o reală plăcere să iei masă ori să bei un pahar de vin împreună cu un prieten, dar atunci când prietenii sunt urologi e la fel de încântător să operezi împreună. 
    Mai jos un instantaneu ad-hoc de vineri în timpul operației de extirpare a unui rinichi tumoral pe cale laparoscopică împreună cu excelentul urolog de la Satu Mare dr Bogdan Feciche. 😇❤️🤗`,
    date: '2022-04-10',
    title: 'Echipa chirurgicală',
  },
  {
    src: "./media/gallery/img/poza_15.jpg",
    width: "1024",
    height: "768",
    type: 'imagine',
    description: `Am găsit poza aceasta pe twitter deși îmi era cunoscută și nu m-am putut abține să nu o împărtășesc…pentru că, din păcate, nu de puține ori, lumea e tentată într-adevăr să vadă exclusiv strălucirea halatului apretat și mai puțin zbuciumul mașinii de spălat care a adus halatul la formă de prezentare. 

    Viața unui medic este, într-adevăr, încă de pe băncile facultății, condiționata de o serie de sacrificii, fie doar și prin prisma celor 6 ani de studiu urmați apoi de alți 5-6 de specializare; pentru asta e nevoie de curaj și disciplină spartană, care apoi vor fi imperios necesare exercitării profesiei; muncă asiduă care implică deopotrivă lupta și competiție, în primul rând cu sine însuși, dublată de o perseverență încăpățânată sunt cele care îl fac să treacă peste dezamăgirile inerente; dubiile în fața bolii, nu de puține ori parșive și înșelătoare precum și frica de eșec pot fi uneori paralizante…pt că eșecul său implică automat incapacitatea de a vindecă pacientul iar sentimentul de vină și temere de a nu fi făcut poate ceva în plus pentru cel aflat în suferința poate fi covârșitor; din acest motiv critică  trebuie acceptată și asumată însă autocritica este cel mai bun mijloc de a merge înainte ; o viața asumată cu riscuri la orice pas, cu nopți albe la căpătâiul bolnavului trebuie să devină aproape un jurământ de credință; un medic va cunoaște în mod indubitabil sentimentul de adversitate uneori din partea celor de la care se aștepta mai puțin…cineva scria deunăzi că există două tipuri de mentori…cei care după ce te formează își atribuie succesul tău și alții care, după ce te vei fi format, fac totul pentru a-ți tăia aripile. Peste toate însă, cuvântul succes din vârful icebergului e prea sărac în a descrie curcubeul de sentimente și emoții pe care un medic le trăiește atunci când, uneori cu lacrimi în ochi, pacientul sau ii strânge mâna în semn de mulțumire!

    Desigur că această postare are un caracter general, însă ea dobândește valențe particulare pentru urmăritorii acestei pagini, tineri medici în pragul examenului de rezidențiat, căutători ai idealurilor într-o lume imperfectă, aflați în față unui altar simbolic, pentru a-și uni viețile profesionale cu cea mai frumoasă meserie din lume!`,
    date: '2021-11-15',
    title: 'Viața unui medic',
  },
  {
    src: "./media/gallery/img/poza_14.jpg",
    width: "1024",
    height: "768",
    type: 'imagine',
    description: `Bună dimineața! 
    Vă “amenințasem” în ultima postare cu o veste interesantă 😜.
    Pentru ca povestea să fie completă, trebuie să ne întoarcem puțîn în timp, mai exact la postarea din 15.02.2020. Vă spuneam acolo despre inițierea unui proiect public de redirecționare a unei părți din venitul impozabil către asociația Unanimis, condusă de către o prietenă inimoasă, pe numele ei Simona Secară. Din banii obținuți, asociația urma să cumpere unul sau mai multe renoureteroscoape flexibile, având în vedere faptul că spitalul public era dotat cu un singur astfel de aparat, clinica noastră fiind una dintre puținele din țară care oferă această metodă intervențională minim invazivă în sistemul public de sănătate.
    Întâmplator sau nu, am reușit în săptămâna mare să finalizăm acest proiect iar astăzi, în ajun de Înviere sunt în măsură să vă împărtășesc vestea cea bună. 
    Clinica de Urologie a SCJU Oradea s-a “îmbogățit” cu două renoureteroscoape flexibile și unul semirigid, echipamente în valoare totală de 22000 euro, grație efortului, persuasiunii și inimii mari a Simonei, pe care nici măcar o pandemie nu a putut să o oprească din înfăptuirea (încă) unui proiect în folosul celor de lângă noi aflați în suferință. Se cuvine, pe această cale, să îi mulțumesc Simonei, în numele colectivului clinicii noastre. 
    Iată că, în pofida tuturor piedicilor, nici măcar o pandemie nu poate opri energia emanată de forța omului atunci când aceasta e canalizată în direcția potrivită, cu atât mai puțîn când energii multiple converg spre un scop comun. 
    Mai jos, de la stânga spre dreapta, lângă mine, Simona Secară mai sus menționată, as. Daniela Luncan, asistentă șefă a blocului operator Urologie, dr. Andreea Țepelea, o tânără speranță a urologiei orădene. 
    Am ținut să împărtășesc cu d-voastră bucuria lăuntrică a realizării acestui proiect de suflet, iată, în ajunul unei zile sfinte. Să fim luminați!`,
    date: '2021-05-01',
    title: 'Proiect public',
  },
  {
    src: `https://www.facebook.com/plugins/video.php?height=476&href=https%3A%2F%2Fwww.facebook.com%2Fdrmihaivarlan%2Fvideos%2F3865216170210623%2F&show_text=false&width=465&t=0`,
    width: "560",
    height: "550",
    type: 'videoclip',
    description: `Cred că introducerea laserului a revoluționat realmente urologia. Beneficiile, cel puțin în chirurgia endoscopică a ureterului, ca să nu mai vorbim despre chirurgia intrarenală, sunt atât de evidente, încât nu suportă comparație cu metodele de litotritie balistică și ultrasonică, folosite până de curând. Deși tehnica din film poartă denumirea de “dusting” (nisipare) despre care am mai vorbit, o colegă a apreciat că “piatra se topește că o lumânare” 😆 Poate schimbăm denumirea tehnicii in “melting” 😜.`,
    date: '2021-02-09',
    title: 'ureteroscopia flexibilă - Aspect intraoperator',
  },
  {
    src: `https://www.facebook.com/plugins/video.php?height=314&href=https%3A%2F%2Fwww.facebook.com%2Fdrmihaivarlan%2Fvideos%2F156525032577463%2F&show_text=false&t=0`,
    width: "560",
    height: "302",
    type: 'videoclip',
    description: `Bună dimineața oameni dragi. La cumpăna dintre poate cel mai greu an de după 1989 respectiv anul pe care ni-l dorim cu toții a fi al eliberării și întoarcerii la normal, dorința mea este de a încerca cel puțin să ne redescoperim pe noi înșine, de a privi înlăuntrul nostru cu ochii sufletului și a înțelege că suntem pasagerii privilegiați ai universului prin această viața mai puțin pentru trup și mai mult pentru suflet! 
    Și pentru că știți deja că sunt un literat, îmi voi permite o parafrază la Dickens, îndemnând pe fiecare dintre voi să reflectați mai mult asupra binecuvântarilor prezentului cu care sunteți din plin hărăziți și mai puțin asupra celor câteva neîmpliniri ale trecutului, de care nimeni nu e scutit! 
    Și ca să încheiem anul optimist...o mică surpriză video ce precede surprizele acestei pagini relaționate cu 2021! Să fim sănătoși! Ținem aproape și în noul an! Să vă meargă rinichii și viețile noastre frumoase înainte! La mulți ani! 🤗❤️🙏`,
    date: '2020-12-31',
    title: 'Video aniversar',
  },
  {
    src: "./media/gallery/img/poza_13.jpg",
    width: "1024",
    height: "768",
    description: `Un eveniment deosebit a avut loc aseară la Hotelul DoubleTree by Hilton - o lansare de carte menită să ajute un elev olimpic aflat în dificultate.

    Copleșiti de emoție și bucuroși de rezultat, mulțumim Rotaract Club Oradea pentru ajutor cât și tuturor celor ce ați răspuns în număr atât de mare invitației de a participa la lansarea cărții domnului dr. Mihai Vârlan, dar și pentru interesul stârnit în a cumpăra cărțile (Dalila și Prima iubire).
    
    Cu ajutorul vostru, trei vise au devenit, sau sunt pe cale să devină realitate. Cel al autorului: acela de a-și vedea cartea publicată, al editoarei: acela de a avea propria editură, și al beneficiarului cauzei: acela de a deveni medic pentru a-și putea ajuta mama și pe cei din jurul său.`,
    type: 'imagine',
    date: '2020-12-20',
    title: 'La mulți ani 2 ani',
  },
  {
    src: "./media/gallery/img/poza_12.jpg",
    width: "1024",
    height: "768",
    description: `12 Aprilie 2018
    Înainte de a părăsi locația de la Spitalul Municipal, am “împrumutat” toți colegii noii ochelarii ai unuia dintre noi pentru a ne poza...Revăzând recent imaginea, am realizat că de fapt, creasem (cu puțină imaginație desigur) o privire în viitor 🙈
    Exercitarea profesiei medicale în România este, din păcate, o adevărată aventură, pe care majoritatea, deși unii o intuiți parțial, e mai bine poate să nu o cunoașteți...sistemul este unul greoi, complet anacronic și rigid, care a rămas înghețat în timpurile de tristă amintire de dinainte de 89’, fără a cunoaște nici cea mai mică îmbunătățire...și probabil cu șanse mici de a cunoaște în viitor...
    Și pentru că de viitor este vorba...și pentru că finalizăm, cu această poză,  acest intermezzo vizual al cunoașterii (ați observat că fiecare poză a fost un leit-motiv pentru a va împărtăși câte ceva din crezurile mele umane/profesionale) vreau să rămânem într-o notă optimistă, amintindu-ne împreună de Peter Drucker, care era convins că cel mai bun mod de a prezice viitorul este de a-l crea tu însuți!`,
    type: 'imagine',
    date: '2020-06-30',
    title: 'Privire în viitor',
  },
  {
    src: "./media/gallery/img/poza_11.jpg",
    width: "1024",
    height: "768",
    type: 'imagine',
    description: `Martie 2018 Weekend în sala de operație împreună cu echipa de rezidenți 
    Pentru un chirurg meseria nu se oprește niciodată vineri după-amiază...așa cum instruirea nu încetează nicidecum după cei 11-12 ani de școala medicală!
    Pe de altă parte chirurgia se face DOAR în echipă! Departe de a fi o simplă noțiune, ECHIPA CHIRURGICALĂ reprezintă un tot unitar în care fiecare, atât seniorul cât și rezidenții, trebuie să beneficieze de pe urma sa! Altfel, rostogolită doar în vorbe, fără a fi întipărita în sufletul și conștiința fiecărui membru, această noțiune cade în derizoriu...tinerii învățăcei devin simple unelte colaboratoare iar chirurgul un biet felcer!
    Scopul final al echipei este, trup și suflet, binele pacientului!`,
    date: '2020-06-19',
    title: 'Echipa chirurgicală',
  },
  {
    src: `https://www.facebook.com/plugins/video.php?height=381&href=https%3A%2F%2Fwww.facebook.com%2Fdrmihaivarlan%2Fvideos%2F709340946481586%2F&show_text=false&t=0`,
    width: "560",
    height: "371",
    type: 'videoclip',
    description: `Clip Bonus 😉 - Noiembrie 2018 - interviu Digi 24
    Orice medic trebuie să fie, în afara valorii profesionale și a instruirii în permanentă, un bun comunicator! Acest atribut poate reprezenta premiza pentru ca, o altă calitate sine qua non și anume empatia, să aibe un impact pozitiv desăvârșit și complet asupra psihicului pacientului! Toate aceste atribute însă pălesc în fața condiției supreme necesare în această profesie... vocația! `,
    date: '2020-06-13',
    title: 'Interviu noul bloc operator SCJUO'
  },
  {
    src: "./media/gallery/img/poza_10.jpg",
    width: "1024",
    height: "768",
    type: 'imagine',
    description: `Trainer la sesiunea hands-on în laparoscopie - Congresul Central European - Octombrie 2018
    Cred cu tărie că adevărata valoare a unui medic nu stă (numai) în numărul de cazuri rezolvate cu succes ori a operațiilor de anvergură reușite, ci mai cu seama în vlăstarele profesionale, mai precis în calitatea elevilor pe care îi îndrumă și a profesioniștilor pe care îi formează! 
    ...”preceptele, lecțiile (m)orale și tot restul învățăturii le voi împărtăși...” Hipocrate
    Indiferent de cât succes se bucură un medic, lipsa elevilor care să îl egaleze/depășească îl transformă într-un biet copac sterp.
    Plecând de la zicerea Talmudului, când îți înveți fiul de fapt înveți pe fiul fiului tău...a crea elevi care te depășesc în medicină echivalează cu a-ți ajuta semenii dincolo de propria ta existența!`,
    date: '2020-06-08',
    title: 'Dă mai departe ce știi'
  },
  {
    src: "./media/gallery/img/poza_9.jpg",
    width: "1024",
    height: "768",
    type: 'imagine',
    description: `Masterclass ureteroscopie flexibilă - Noiembrie 2018 - spitalul Tenon Paris
    Cred că pentru a ajunge și rămâne în top, orice medic trebuie să meargă de două-trei ori pe an într-o clinică de referință universitară din lume, pentru a compara nivelul său cu ceea ce se întâmpla acolo. Fără această permanentă autoevaluare, impresia strict subiectivă asupra muncii tale va fi una din ce în ce mai depărtată de adevăr! 
    Pentru că eu cred că ureterorenoscopia flexibilă se va definitiva în următorii ani drept cea mai eficace și non-invazivă metodă de terapie a litiazei renale, după Masterclass-ul de la Patras am ținut neapărat să “rafinez” tehnica  cu un Masterclass la probabil cel mai bun urolog din lume în această privința, prof. Olivier Traxer de la Paris.
    Nu poți atinge excelența decât alături de cei mai buni!`,
    date: '2020-06-04',
    title: 'Masterclass Paris - urmărind geniul'
  },
  {
    src: "./media/gallery/img/poza_8.jpg",
    width: "1024",
    height: "768",
    type: 'imagine',
    description: `Masterclass EAU de urolitiaza Patras (Grecia) - Iunie 2018 
    Probabil unul dintre cele mai bune Masterclass adresate litiazei renale a însumat posibilitatea de a testa tehnica abordului percutanat minim invaziv al rinichiului respectiv ureterorenoscopiei flexibile folosind instrumentar real și real time surgery pe pelvitrainer.
    Înainte de a translationa orice tehnică chirurgicală în sala de operație ea trebuie desăvârșita pe modele astfel încât siguranța pacientului să reprezinte o prioritate absolută! `,
    date: '2020-06-01',
    title: 'Masterclass Grecia'
  },
  {
    src: "./media/gallery/img/poza_7.jpg",
    width: "1024",
    height: "768",
    type: 'imagine',
    description: `Masterclass de miniperc - Februarie 2018 Hall în Tirol - Austria 
    Paradigma abordului percutanat al rinichiului (NLP) s-a schimbat în ultimii ani, în sensul miniaturizării la 1/2 sau 1/3 din diametrele instrumentelor utilizate, reducând astfel trauma asupra rinichiului respectiv pierderile unităților nefronale de la 12-15% la 6-7%.
    Tehnica este ușor diferită iar pentru a o stăpâni am ales să o învăț de la unul dintre “maeștrii genului” prof. dr. Udo Nagele.`,
    date: '2020-05-28',
    title: 'În căutarea noului'
  },
  {
    src: "./media/gallery/img/poza_6.jpg",
    width: "1024",
    height: "768",
    type: 'imagine',
    description: `1 Noiembrie 2016 - prima poză în Israel - spitalul Beillinson-Hasharon Petah-Tikva/Tel-Aviv - fellowship în laparoscopie; poză în dimineața aterizării în fața spitalului în care aveam să îmi petrec 3 luni extraordinare.
    Cred că atunci când vrei să desăvârșești învățarea unei noi metode, oricare ar fi ea, cursurile, work-shop-urile și hand on training-urile trebuie să se finalizeze cu unul-două master-class-uri sau ideal cu un fellowship (bursă). Pentru mine experiența în Israel mi-a prilejuit ocazia de a învața laparoscopie și robotică de la unii dintre cei mai buni din lume. Am avut privilegiul să lucrez cu dr. Andrei Nadu, profesorul Offer Yossepovitch, oameni de o valoare profesională și umană inestimabilă, a căror prietenie mă onorează!`,
    date: '2020-05-26',
    title: 'Experiența israeliană'
  },
  {
    src: "./media/gallery/img/poza_5.jpg",
    width: "1024",
    height: "768",
    type: 'imagine',
    description: `În 2014, ca tânăr specialist, participând la numeroase congrese și conferințe de profil, am înțeles că laparoscopia este prezentul în chirurgia urologică vest-europeană și că va reprezenta fără doar și poate viitorul în România. Drept consecință, am început să frecventez anual “hands-on training-urile” în laparoscopie de la UMF Cluj-Napoca, susțînând în subsidiar, deși nimeni nu îmi dădea vreo șansa, necesitatea introducerii metodei și în Oradea! Astăzi, noul bloc operator Urologie  al SCJUO este dotat din fericire cu un turn de laparoscopie 3D! 
    Viitorul aparține generației mele! 
    Împreună cu restul colegilor am încredere că vom ridica stindardul urologiei orădene mai sus, în fiecare zi tot mai sus!.
    `,
    date: '2020-05-25',
    title: 'Training laparoscopie'
  },
  {
    src: "./media/gallery/img/poza_4.jpg",
    width: "1024",
    height: "768",
    type: 'imagine',
    description: `În locația temporară de la Spitalul Municipal “dr. Gavril Curteanu” în Iunie 2015, la o vizită în noul bloc operator modernizat al spitalului.`,
    date: '2020-05-22',
    title: 'Îmbrățișând noul'
  },
  {
    src: "./media/gallery/img/poza_3.jpg",
    width: "1024",
    height: "768",
    type: 'imagine',
    description: `O poză nu extrem de reușită tehnic însă la care țin foarte mult. Cândva prin 2012, in primul an ca medic specialist, prima intervenție de extragere a unui calcul (piatră) dintr-un rinichi! La acel moment am efectuat o operație de chirurgie clasică...astăzi o intervenție asemănătoare o realizez prin orificii naturale ale organismului cu ajutorul laserului chirurgical...o tempora 😉`,
    date: '2020-05-19',
    title: 'Prima operație'
  },
  {
    src: "./media/gallery/img/poza_2.jpg",
    width: "1024",
    height: "768",
    type: 'imagine',
    description: `De această dată în August 2011 ca rezident de an terminal, în cadrul fellowshipului de uro-oncologie desfășurat la Institutul European de Oncologie din Milano, la consola unui instrument fenomenal, robotul chirurgical da Vinci!`,
    date: '2020-05-17',
    title: 'Experiență italiană'
  },
  {
    src: "./media/gallery/img/poza_1.jpg",
    width: "1024",
    height: "768",
    type: 'imagine',
    description: `Bună dimineața! Având în vedere că în general dar în această perioada în mod particular avem nevoie de un status imunitar bun, până în momentul în care ne vom relua activitatea medicală normală, moment pe care îl sperăm a fi cât mai curând, am decis să vizionam împreună un fel de AMR în imagini...astfel încât să ne creștem împreună buna dispoziție. Mă refer la a împărtăși cu d-voastră câteva instantanee ale vieții profesionale de până acum astfel încât această pagină să devină (și) mai friendly 😉
    Astăzi aceasta este o poză datată 11 Ianuarie 2011 😳 atunci când, în ipostază de proaspăt rezident de an terminal, memoria fotografică reținea imaginea colectivului de atunci al clinicii de urologie a SCJU Oradea în vechiul bloc operator al spitalului! 
    Invit la descoperirea în poză a subsemnatului 😆
    PS. La o “reanaliza” am constatat că poză e datată 2008 pentru că o serie de colegi care erau mai mari sunt prezenți în poză...deci eram anul 2 de rezidențiat...tinerel de tot 🙈...asta așa din spirit de rigurozitate 😉`,
    date: '2020-05-14',
    title: 'Începuturi'
  },
  {
    src: `https://www.facebook.com/plugins/video.php?height=405&href=https%3A%2F%2Fwww.facebook.com%2Fdrmihaivarlan%2Fvideos%2F174056893863473%2F&show_text=false&t=0`,
    width: "560",
    height: "390",
    type: 'videoclip',
    description: `Acesta este aspectul unui rinichi la 4 săptămâni după o procedura de ureteroscopie flexibilă efectuată pentru prezența a patru pietre la acest nivel. După cum bine se poate observa, acel nisip fin rezultat din tehnica dusting este complet eliminat. Procedura lasă rinichiul intact, fără perforații și risc de sângerare. A două zi postinterventional pacientul este externat și poate relua activitatea curentă socio-profesională după 2-3 zile!`,
    date: '2020-02-07',
    title: 'Ureterorenoscopia flexibilă - rinichiul vindecat'
  },
  {
    src: `https://www.facebook.com/plugins/video.php?height=316&href=https%3A%2F%2Fwww.facebook.com%2Fdrmihaivarlan%2Fvideos%2F2372381336372165%2F&show_text=false&t=0`,
    width: "560",
    height: "302",
    type: 'videoclip',
    description: `Aspectul final al fragmentelor submilimetrice rezultate în urma ureteroscopiei flexibile. Observați aspectul extrem de fin al acestora care practic sunt “măturate” de fluxul de lichid al aparatului. Eliminarea se face spontan în câteva zile/săptămâni după intervenție. A doua zi postinterventional pacientul este externat și poate relua activitatea curentă.`,
    date: '2019-08-10',
    title: 'Ureterorenoscopia flexibilă - aspect final'
  },
  {
    src: `https://www.facebook.com/plugins/video.php?height=314&href=https%3A%2F%2Fwww.facebook.com%2Fdrmihaivarlan%2Fvideos%2F722157334901141%2F&show_text=false&t=0`,
    width: "514",
    height: "302",
    type: 'videoclip',
    description: `Intervenția de ureterorenoscopie flexibilă se realizează cu ajutorul unei fibre laser care pulverizează piatra în fragmente submilimetrice care mai apoi se elimina spontan odată cu urina. Tehnica poartă denumirea de dusting (nisipare).`,
    date: '2019-08-10',
    title: 'Ureterorenoscopia flexibilă'
  },
];
