import { Typography, Box } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import { makeStyles } from "@mui/styles";

// Components
import Card from "./Card";

export default function VideoThumbnail({ videoNbr, title }) {
  const classes = useStyles();

  return (
    <Card padded className="video-gallery-card">
      <Link to={`/pastila-de-urologie/${videoNbr}`} className={classes.root}>
        <Box display="flex" flexDirection="column">
          <Typography
            variant="h6"
            paragraph
            align="center"
            color="primary"
            fontWeight={"bold"}
          >
            <i class="fas fa-pills" />
            {/* {videoNbr} */}
          </Typography>
          <Typography paragraph align="center">
            Episodul {videoNbr}
          </Typography>
          <Typography align="center" className={classes.title}>
            {title}
          </Typography>
        </Box>
      </Link>
    </Card>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  title: {
    height: 48,
    fontSize: '18px !important',

    [theme.breakpoints.down('md')]: {
      fontSize: '16px !important',
    }
  },
}));
