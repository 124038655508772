import { Container } from "@mui/material";
import { makeStyles } from "@mui/styles";

export const PaddedContainer = ({ children, maxWidth = "lg" }) => {
  const classes = useStyles();

  return (
    <Container maxWidth={maxWidth} className={classes.root}>
      {children}
    </Container>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),

    [theme.breakpoints.down("sm")]: {
      marginTop: 0,
    },
  },
}));
