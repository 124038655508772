const palette = {
    primary: {
      light: '#c9e8ea',
      main: '#63B8B6',
      disabled: '#00adee52',
      transparent: 'rgba(170, 210, 209, 0.3)',
      dark: '#549B99',
      darker: '#6EB6B4',
      darkText: '#437c7a'
    },
    secondary: {
      main: '#c9e8ea'
    },
    default: {
      main: '#DEF1F2',
      dark: '#d9eef0'
    },
    common: {
      darkBlue: '#031D44',
      blue: '#031D44',
      yellow: '#FCA311',
      turquoise: '#157C7D',
      green: '#33957c',
      base: '#F8F9F9',
      adminBase: '#5a5a5a',
      grey: '#E5E5E5',
      footer: '#171717',
      offWhite: '#f5f5f5',
      mainGreen: '#a2bf63',
      carouselBtnBck: 'rgba(248,249,249, 0.7)'
    }
  }
  
  export default palette
  