import React, { useEffect, useState } from "react";
import {
  Button,
  Grid,
  Slide,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";

// Container
import { PaddedContainer } from "../../common/layout/containers";
import Api from "../../../api/Api";
import { API_ROUTES } from "../../../api/api-routes";
import { useHistory, useParams } from "react-router-dom";
import { ADMIN_ROUTES } from "../../../types/enum";

export default function AdminVideoEdit() {
  const [video, setVideo] = useState({
    title: "",
    id: null,
    number: null,
    link: null,
  });
  const [open, setIsOpen] = useState(false);
  const [serverResponse, setServerResponse] = useState(null);

  const history = useHistory();
  const { id } = useParams();

  useEffect(() => {
    id && getData(id);
  }, [id]);

  const getData = async (id) => {
    const video = await Api.get(API_ROUTES.VIDEOS + "/" + id);
    setVideo(video);
  };

  const save = async (id) => {
    const endpoint = id
      ? API_ROUTES.CRUD.EDIT_VIDEO
      : API_ROUTES.CRUD.ADD_VIDEO;
    const { message, newId } = await Api.post(endpoint, { video });
    if (newId === id) {
      history.push(ADMIN_ROUTES.VIDEO + "/" + id);
    } else {
      setServerResponse(message);
    }
  };

  const handleChange = (event, key) => {
    setVideo({...video, [key]: event.target?.value});
  };

  return (
    <PaddedContainer>
      <Typography variant="h6" gutterBottom>
        {id ? "Editeaza articolul" : "Adauga un nou articol"}
      </Typography>
      <hr />

      <Grid container pt={3} spacing={4}>
        <Grid item xs={12}>
          <TextField
            value={video.title}
            onChange={(event) => handleChange(event, 'title')}
            fullWidth
            label="Titlu"
            variant="filled"
          ></TextField>
        </Grid>
        <Grid item xs={12}>
          <TextField
            value={video.link}
            onChange={(event) => handleChange(event, 'link')}
            fullWidth
            variant="filled"
            label="Link"
          ></TextField>
        </Grid>
        <Grid item xs={12}>
          <TextField
            value={video.number}
            onChange={(event) => handleChange(event, 'number')}
            variant="filled"
            label="Nr episod"
          ></TextField>
        </Grid>
        <Grid item xs={12} textAlign="center">
          <Button onClick={save} color="primary" variant="contained">
            {id ? "Salveaza modificarile" : "Adauga articolul"}
          </Button>
        </Grid>
      </Grid>

      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={open}
        message={serverResponse}
        TransitionComponent={(props) => <Slide {...props} direction="down" />}
        autoHideDuration={2000}
        onClose={() => setIsOpen(false)}
        key={"topRight"}
      />
    </PaddedContainer>
  );
}
