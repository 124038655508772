import React from "react";
import { Grid, useMediaQuery, Slide } from "@mui/material";
import MediaCard from "./MediaCard";
import MediaDetails from "./MediaDetails";
import GalleryDetail from "../GalleryDetail";
import Card from "../../../common/components/data-display/Card";
import VideoGalleryItem from "./GalleryVideo";

const DesktopTimeline = ({ mediaItem, index, classes, handleOpenModal }) => (
  <>
    <Grid
      item
      sm={6}
      xs={12}
      className={classes.borderRight}
    >
      <div className={classes.dot} />

      {index % 2 === 0 ? (
        <MediaCard mediaItem={mediaItem} index={index} handleClick={handleOpenModal} />
      ) : (
        <MediaDetails mediaItem={mediaItem} classes={classes} index={index} handleClick={handleOpenModal}  />
      )}
    </Grid>
    <Grid
      item
      sm={6}
      xs={12}
      className={classes.paddingLeft}
    >
      {index % 2 === 1 ? (
        <MediaCard mediaItem={mediaItem} index={index} handleClick={handleOpenModal}  />
      ) : (
        <MediaDetails mediaItem={mediaItem} classes={classes} index={index} handleClick={handleOpenModal}  />
      )}
    </Grid>
  </>
);

const MobileTimeline = ({ mediaItem, classes, handleOpenModal }) => (
  <>
    <Grid
      item
      md={6}
      sm={12}
      className={classes.borderLeft}
    >
      <div className={classes.mobileDot} />
      <Card onClick={() => handleOpenModal(mediaItem)}>
        {mediaItem.type === "imagine" ? (
          <img
            alt="imagine galerie"
            src={mediaItem.src}
            className={`gallery-img ${classes.gridImage}`}
          />
        ) : (
          <VideoGalleryItem src={mediaItem.src} noPlay />
        )}
      </Card>
    </Grid>
    <Grid
      item
      md={6}
      sm={12}
      className={classes.borderLeft}
      onClick={handleOpenModal}
    >
      <MediaDetails mediaItem={mediaItem} classes={classes} handleClick={handleOpenModal}  />
    </Grid>
  </>
);

const GalleryTimeline = ({ galleryItems, filterTypes, classes }) => {
  const [selectedMediaItem, setSelectedMediaItem] = React.useState(null);
  const [openModal, setOpenModal] = React.useState(false);

  const handleOpen = (mediaItem) => {
    setSelectedMediaItem(mediaItem);
    setOpenModal(true);
  };

  const handleClose = () => {
    setSelectedMediaItem(null);
    setOpenModal(false);
  };

  const desktopTimeline = useMediaQuery((theme) => theme.breakpoints.up("sm"));

  return (
    <>
      <Slide direction="right" in={true} mountOnEnter unmountOnExit>
        <Grid container pt={6}>
          {galleryItems
            .filter(({ type }) =>
              filterTypes.length > 0
                ? filterTypes.some((selectedType) => selectedType === type)
                : true
            )
            .map((mediaItem, index) =>
              desktopTimeline ? (
                <DesktopTimeline
                  mediaItem={mediaItem}
                  index={index}
                  classes={classes}
                  handleOpenModal={() => handleOpen(mediaItem)}
                />
              ) : (
                <MobileTimeline
                  mediaItem={mediaItem}
                  classes={classes}
                  handleOpenModal={() => handleOpen(mediaItem)}
                />
              )
            )}
        </Grid>
      </Slide>

      {selectedMediaItem && (
        <GalleryDetail
          mediaItem={selectedMediaItem}
          handleClose={handleClose}
          open={openModal}
          classes={classes}
        />
      )}
    </>
  );
};

export default GalleryTimeline;
