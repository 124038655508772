import React from "react";
import PropTypes from "prop-types";
import { Button, Box, useMediaQuery } from "@mui/material";
import { Link } from "react-router-dom";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { useTheme } from "@mui/styles";

export default function BackNavigationButton({ route, text }) {
  const theme = useTheme();
  const matchesDesktop = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <Link to={route}>
      <Box mb={matchesDesktop ? 1 : 5}>
        <Button
          variant="contained"
          color="default"
          startIcon={<ArrowBackIosNewIcon />}
        >
          {text}
        </Button>
      </Box>
    </Link>
  );
}

BackNavigationButton.propTypes = {
  route: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};
