import { SORT_TYPE } from "../Filters/types";

export const initialFiltersState = {
  sortOrder: SORT_TYPE.DESC,
  keyword: "",
  tags: [],
};

export const initialState = {
  filters: initialFiltersState,
  articles: [],
  tags: [],
  loading: false,
};

export const reducers = (state, action) => {
  switch (action.type) {
    case "SET_FILTERS":
      return { ...state, filters: action.payload.filters, loading: true };
    case "SET_ARTICLES_AND_TAGS":
      return {
        ...state,
        articles: action.payload.articles,
        tags: action.payload.tags,
        loading: false,
      };
    default:
      return;
  }
};
