import React, { useEffect, useState } from "react";
import {
  Button,
  Grid,
  IconButton,
  Slide,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import MobileDatePicker from "@mui/lab/MobileDatePicker";
import DeleteIcon from "@mui/icons-material/Delete";

// Container
import { PaddedContainer } from "../../common/layout/containers";
import Api from "../../../api/Api";
import { API_ROUTES } from "../../../api/api-routes";
import { useHistory, useParams } from "react-router-dom";
import { ADMIN_ROUTES } from "../../../types/enum";

export default function AdminArticleEdit() {
  const [article, setArticle] = useState({
    id: null,
    title: "",
    date: null,
    paragraphs: [],
    imageSrc: "",
    tags: [],
  });
  const [open, setIsOpen] = useState(false);
  const [serverResponse, setServerResponse] = useState(null);

  const history = useHistory();
  const { id } = useParams();

  useEffect(() => {
    id && getData(id);
  }, [id]);

  const getData = async (id) => {
    const article = await Api.get(API_ROUTES.ARTICLES + "/" + id);
    setArticle(article);
  };

  const save = async (id) => {
    const endpoint = id
      ? API_ROUTES.CRUD.EDIT_ARTICLE
      : API_ROUTES.CRUD.ADD_ARTICLE;
    const { message, newId } = await Api.post(endpoint, { article });
    if (newId === id) {
      history.push(ADMIN_ROUTES.ARTICLE + "/" + id);
    } else {
      setServerResponse(message);
    }
  };

  const handleParagraphChange = (event, index) => {
    const paragraphs = {...article}.paragraphs;
    paragraphs[index] = event.target?.value;
    setArticle({ ...article, paragraphs });
  };

  const handleChange = (event, key) => {
    setArticle({ ...article, [key]: event.target?.value });
  };

  const handleDateChange = (date) => {
    setArticle({ ...article, date });
  }

  const handleAddParagraph = () => {
    const newParagraphs = article.paragraphs;
    newParagraphs.push("");
    setArticle({ ...article, paragraphs: newParagraphs });
  };

  const handleDeleteParagraph = (index) => {
    const newParagraphs = article.paragraphs;
    newParagraphs.splice(index, 1);
    setArticle({ ...article, paragraphs: newParagraphs });
  }

  const Paragraph = ({ index }) => (
    <Grid container spacing={2}>
      <Grid item xs={11}>
        <TextField
          value={article.paragraphs[index]}
          onChange={(event) => handleParagraphChange(event, index)}
          fullWidth
          variant="filled"
          label="Paragraf"
          multiline
        />
      </Grid>
      <Grid item xs={1}>
        <IconButton
          aria-label="delete"
          color="warning"
          onClick={() => handleDeleteParagraph(index)}
        >
          <DeleteIcon />
        </IconButton>
      </Grid>
    </Grid>
  );

  // TODO: cancel btn/discrd/return, draggable
  // edit galerie
  // img & video upload
  // sau video embed
  return (
    <PaddedContainer>
      <Typography variant="h6" gutterBottom>
        {id ? "Editeaza articolul" : "Adauga un nou articol"}
      </Typography>
      <hr />

      <Grid container pt={3} spacing={4}>
        <Grid item xs={12}>
          <TextField
            value={article.title}
            onChange={(event) => handleChange(event, 'title')}
            fullWidth
            label="Titlu"
            multiline
          ></TextField>
        </Grid>
        <Grid item xs={12}>
          <MobileDatePicker
            label="Data"
            inputFormat="yyyy-mm-dd"
            variant="filled"
            value={article.date}
            onChange={handleDateChange}
            renderInput={(params) => <TextField {...params} />}
          />
        </Grid>
        <Grid item xs={12} textAlign="right">
          <Button
            startIcon={<AddIcon />}
            onClick={handleAddParagraph}
            color="primary"
          >
            Adauga paragraf
          </Button>
          {article.paragraphs.map((paragraph, index) => (
            <Paragraph index={index} />
          ))}
        </Grid>
        <Grid item xs={12}>
          <TextField
            value={article.imageSrc}
            onChange={(event) => handleChange(event, 'imageSrc')}
            variant="filled"
            label="Imagine"
            fullWidth
            multiline
          ></TextField>
        </Grid>
        
        <Grid item xs={12} textAlign="center">
          <Button onClick={save} color="primary" variant="contained">
            {id ? "Salveaza modificarile" : "Adauga articolul"}
          </Button>
        </Grid>
      </Grid>

      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={open}
        message={serverResponse}
        TransitionComponent={(props) => <Slide {...props} direction="down" />}
        autoHideDuration={2000}
        onClose={() => setIsOpen(false)}
        key={"topRight"}
      />
    </PaddedContainer>
  );
}
