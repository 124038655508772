import { makeStyles } from "@mui/styles";

const Logo = () => {
  const classes = useStyles();
  
  return (
    <img
      src="/media/logo/mihailogo-f.png"
      className={classes.logoImg}
      alt="logo"
    />
  );
};

const useStyles = makeStyles((theme) => ({
  logoImg: {
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
    height: 28,
    [theme.breakpoints.down('md')]: {
      height: 23,
    }
  },
}));

export default Logo;
