import { ADMIN_ROUTES } from "../../../types/enum";

export const ROUTES = [ // CRUD routes
  {
    name: "Articole",
    link: ADMIN_ROUTES.ARTICLES,
    label: 'Articole',
    id: '/articles',
  },
  {
    name: "Pastila de urologie",
    link: ADMIN_ROUTES.VIDEOS,
    label: 'Pastila de urologie',
    id: '/pastila',
  },
];

export const ALL_ROUTES = [
  {
    name: "Acasa",
    link: ADMIN_ROUTES.HOME,
    label: 'Acasa',
    id: '',
  },
  {
    name: "Login",
    link: ADMIN_ROUTES.LOGIN,
    label: 'Login',
    id: '/login',
    hide: true,
  },
  ...ROUTES
];