import { useMediaQuery } from "@mui/material";
import { makeStyles } from "@mui/styles";

const VideoGalleryItem = ({ src, noPlay, title, ...props }) => {
  const desktop = useMediaQuery((theme) => theme.breakpoints.up("md"));

  const classes = useStyles();

  return (
    <div className={classes.container}>
      {noPlay && <div className={classes.overlay} />}
      <iframe
        title={title}
        src={src}
        style={{ border: "none", overflow: "hidden" }}
        scrolling="no"
        frameborder="0"
        allow="clipboard-write; encrypted-media; picture-in-picture; web-share"
        allowFullScreen
        height={desktop ? "170" : "auto"}
        {...props}
      ></iframe>
    </div>
  );
};

const useStyles = makeStyles(() => ({
  root: {
    position: 'relative'
  },
  overlay: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    zIndex: '100'
  }
}));

export default VideoGalleryItem;
