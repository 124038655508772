export const ROUTES = [
  {
    name: "Acasă",
    link: "/",
    id: "/",
  },
  {
    name: "Despre mine",
    link: "/bio",
    id: "bio",
  },
  {
    name: "Articole",
    link: "/articles",
    id: "articles",
  },
  {
    name: "Pastila de urologie",
    link: "/pastila-de-urologie",
    id: "pastila-de-urologie",
  },
  {
    name: 'Galerie',
    link: '/gallery',
    id: 'gallery'
  },
  {
    name: "Recenzii",
    link: "/reviews",
    id: "reviews",
  },
  {
    name: "Contact",
    link: "/contact",
    id: "contact",
  },
];
