import React from "react";
import { Box, useMediaQuery } from "@mui/material";
import { format } from "date-fns";

import palette from "../../../../assets/theme/palette";
import { DATE_FORMAT } from "../../../util/date-utils";

const MediaDetails = ({ mediaItem, classes, index, handleClick }) => {
  const desktop = useMediaQuery((theme) => theme.breakpoints.up("md"));

  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      justifyContent="center"
      alignItems={index % 2 === 1 ? "flex-end" : "flex-start"}
      height={"100%"}
    >
      <Box
        width={desktop ? "50%" : "80%"}
        onClick={handleClick}
        style={{ cursor: "pointer" }}
      >
        <Box pb={2} color={palette.primary.darkText}>
          <div>{format(new Date(mediaItem.date), DATE_FORMAT)}</div>
          <div>
            <b>{mediaItem.title}</b>
          </div>
        </Box>
        <p>
          {mediaItem.description?.slice(0, 150)}
          <span className={classes.readMore}>[...Citește mai departe]</span>
        </p>
      </Box>
    </Box>
  );
};

export default MediaDetails;
