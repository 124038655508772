import React, { useEffect, useState } from "react";
import { Box, Button, Typography } from "@mui/material";

// Components
import { PaddedContainer } from "../../common/layout/containers";
import ReviewCard from "../../common/components/data-display/ReviewCard";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";

// data
import Api from "../../../api/Api";
import { API_ROUTES } from "../../../api/api-routes";
import { RESPONSE } from "../../../api/responses";

export default function ReviewPage() {
  const [reviews, setReviews] = useState([]);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    const reviews = await Api.get(API_ROUTES.REVIEWS);
    setReviews(reviews || []);
  };

  return (
    <PaddedContainer>
      <Typography variant="h6" gutterBottom>
        Experiențele pacienților
      </Typography>
      <hr />

      <Box>
        {reviews.map((review, index) => (
          <ReviewCard key={index} {...review} />
        ))}
      </Box>

      <Box display="flex" justifyContent={"center"} pt={2}>
        {reviews.length > 0 && (
          <a
            href="https://www.facebook.com/drmihaivarlan/reviews"
            target={"_blank"}
            rel="noreferrer"
          >
            <Button
              variant="contained"
              color="secondary"
              endIcon={<OpenInNewIcon fontSize="large" />}
            >
              {RESPONSE.READ_MORE_REVIEWS}
            </Button>
          </a>
        )}
      </Box>

      {reviews.length === 0 && (
        <Box pt={4}>
          <Typography>{RESPONSE.NO_REVIEWS}</Typography>
        </Box>
      )}
    </PaddedContainer>
  );
}
