import {
  useReducer,
  useCallback,
  createContext,
  useEffect,
  useRef,
} from "react";
import { useLocation } from "react-router-dom";
import qs from "qs";

import { reducers, initialState, initialFiltersState } from "./reducers";

import Api from "../../../../api/Api";
import { API_ROUTES } from "../../../../api/api-routes";

const DEBOUNCE_MS = 1000;

export const ArticlesContext = createContext();

export const ArticlesProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducers, initialState);

  const { search } = useLocation();

  let debounceTimeout = useRef();

  const setArticlesAndTags = useCallback(
    (articles, tags) => {
      dispatch({ type: "SET_ARTICLES_AND_TAGS", payload: { articles, tags } });
    },
    [dispatch]
  );

  const getData = async (filters) =>
    await Api.get(API_ROUTES.ARTICLES, filters);

  const getArticlesAndTags = useCallback(
    async (filters) => {
      const data = await getData(filters);
      setArticlesAndTags(data?.articles || [], data?.tags || []);
    },
    [setArticlesAndTags]
  );

  const setFilters = useCallback(
    (filters) => {
      dispatch({ type: "SET_FILTERS", payload: { filters } });

      clearTimeout(debounceTimeout.current);
      debounceTimeout.current = setTimeout(
        () => getArticlesAndTags(filters),
        DEBOUNCE_MS
      );
    },
    [dispatch, getArticlesAndTags]
  );

  useEffect(() => {
    getArticlesAndTags(initialFiltersState);

    return () => clearTimeout(debounceTimeout.current);
  }, [getArticlesAndTags]);

  useEffect(() => {
    const queryFilters = qs.parse(search.substring(1, search.length));
    setFilters({
      ...initialFiltersState,
      keyword: queryFilters.keyword || "",
      tags: queryFilters.tags ? queryFilters.tags.split(',') : [],
    });
  }, [search, setFilters]);

  return (
    <ArticlesContext.Provider
      value={[
        state,
        {
          setFilters,
        },
      ]}
    >
      {children}
    </ArticlesContext.Provider>
  );
};

export default ArticlesProvider;
