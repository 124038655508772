export const ADMIN_PREFIX = "/modules/admin";

export const ROUTES = {
  REVIEWS: "/reviews",
  ARTICLES: "/articles",
  VIDEOS: "/pastila-de-urologie",
  BIO: "/bio",
  CONTACT: "/contact",
  HOME: "/",
  GALLERY: "/gallery" 
};

export const ADMIN_ROUTES = {
  HOME: ADMIN_PREFIX,
  LOGIN: `${ADMIN_PREFIX}/login`,
  ARTICLES: `${ADMIN_PREFIX}/articles`,
  ARTICLE: `${ADMIN_PREFIX}/article`,
  VIDEOS: `${ADMIN_PREFIX}/pastila`,
  VIDEO: `${ADMIN_PREFIX}/episod`,
};
