import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import { Link } from "react-router-dom";

export default function NavScrollableTabs({
  tabs,
  initialValue,
  onTabClickCallback = () => {},
  darkTheme = false
}) {
  const [value, setValue] = React.useState(initialValue);
  const classes = useStyles();

  useEffect(() => {
    initialValue && setValue(initialValue);
  }, [initialValue]);

  const onTabClick = (tab) => {
    setValue(tab);
    onTabClickCallback();
  };

  return (
    <div className={classes.wrap}>
      {tabs.map((tab) => (
        <Link to={tab.link} key={tab.link} className={classes.link}>
          <div
            className={`${value?.name === tab.name ? classes.active : ""} ${
              classes.tab
            } ${darkTheme ? 'dark-theme' : ''}`}
            role="tab"
            onClick={() => onTabClick(tab)}
          >
            {tab.html}
          </div>
        </Link>
      ))}
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  wrap: {
    height: "100%",
    display: "flex",
    alignItems: "center",

    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(5),
    },

    "& a": {
      textDecoration: "none",
    },
  },
  tab: {
    margin: `0 ${theme.spacing(2)}px`,
    padding: theme.spacing(1),

    [theme.breakpoints.down("sm")]: {
      margin: theme.spacing(1),
    },
  },
  active: {
    borderBottom: `2px solid ${theme.palette.primary.main}`,
    color: `${theme.palette.primary.main} !important`,
    fontWeight: "500 !important",
    '&.dark-theme' : {
      borderBottom: `2px solid ${theme.palette.common.darkBlue}`,
    }
  },
  link: {
    color: theme.palette.common.darkBlue,
  },
}));

NavScrollableTabs.propTypes = {
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      html: PropTypes.node.isRequired,
    })
  ).isRequired,
  initialValue: PropTypes.shape({
    name: PropTypes.string,
    html: PropTypes.node,
  }),
};
