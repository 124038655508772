import React from 'react'
import { Box, Typography } from '@mui/material';
import { makeStyles } from "@mui/styles";

import './styles.scss'

const renderSocialLink = (link, containerClass, fontAwesomeClass) => (
    <a href={link} target="_blank" rel="noreferrer">
        <div className={`social-link-container ${containerClass}`}>
            <i className={`fab ${fontAwesomeClass}`}></i>
        </div>
    </a>
);

const SocialLinks = () => {
    const classes = useStyles()

    return (
        <Box className={classes.root}>
            <Box pb={3}><Typography><b>Mă găsești și aici: </b></Typography></Box>
            <div className={`${classes.root} social-links`}>
                {renderSocialLink("https://www.facebook.com/drmihaivarlan", "fb", "fa-facebook-f")}
                {renderSocialLink("https://twitter.com/mihai_varlan", "twitter", "fa-twitter")}
                {renderSocialLink("https://www.instagram.com/mihai.varlan.96/", "insta", "fa-instagram")}
                {renderSocialLink("https://www.youtube.com/channel/UCHIr1yBO72SFp8iuUxvt61A", "yt", "fa-youtube")}
            </div>
        </Box>
    )
}


const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
}))

export default SocialLinks;