import { Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
import Card from "./data-display/Card";

export default function SearchResult({
  title,
  text,
  hasReadMore = false,
}) {
  const classes = useStyles();
  return (
    <Card className={`${classes.root} search-result`}>
      <Typography
        variant="subtitle1"
        color={"primary"}
        className={classes.title}
        gutterBottom
      >
        {title}
      </Typography>
      <Typography>
        {text?.slice(0, 200) + ' '}
        {text && hasReadMore && (
          <span className={classes.readMore}>[...Citește mai departe]</span>
        )}
      </Typography>
    </Card>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    fontSize: "16px !important",
    cursor: "pointer !important",
    padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
    border: `1px solid ${theme.palette.primary.dark}`,
    borderRadius: 5,
    width: "100%",

    "&:hover": {
      backgroundColor: theme.palette.primary.light,
    },

    [theme.breakpoints.down("md")]: {
      fontSize: "13px !important",
    },
  },
  title: {
    color: `${theme.palette.primary.dark} !important`,
    fontWeight: "600 !important",
  },
  readMore: {
    fontWeight: "bold",
  },
}));
