import React, { useEffect, useState } from "react";
import { Button, Typography, Box } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

// Components
import { PaddedContainer } from "../../common/layout/containers";
import ReadMoreSection from "./ReadMoreSection";
import ArticleHero from "../../common/components/data-display/ArticleHero";
import Loader from "../../common/components/loaders/Loader";

// Others
import Filters from "./Filters/Filters";
import useArticles from "./hooks/use-articles";
import { hasActiveFilters } from "./util/filters-util";
import { RESPONSE } from "../../../api/responses";

export const MAX_SUGGESTED = 3;

export default function ArticlePage() {
  const [{ articles, tags, filters, loading }] = useArticles();
  const [showAll, toggleShowAll] = useState(false);

  const preloadImage = () => {
    ["./media/AdobeStock_132199014.jpeg"].forEach((picture) => {
      const img = new Image();
      img.src = picture.fileName;
    });
  };

  useEffect(() => {
    preloadImage();
  });

  return (
    <PaddedContainer>
      <Typography variant="h6" gutterBottom>
        {!hasActiveFilters(filters)
          ? "Articole"
          : "Articolele care corespund căutării tale"}
      </Typography>
      <hr />

      <Filters tags={tags} />

      {loading && <Loader />}
      {!loading && articles && articles.length > 0 && (
        <>
          <ArticleHero
            {...articles[0]}
          />
          <Box pt={4}>
            {articles.length > 1 && (
              <ReadMoreSection showAll={showAll} articles={articles.slice(1, articles.length)} />
            )}
          </Box>

          {articles.length > MAX_SUGGESTED  + 1 && (
            <Box py={5} display="flex" justifyContent="center">
              <Button
                variant="contained"
                color="secondary"
                startIcon={
                  showAll ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />
                }
                onClick={() => toggleShowAll(!showAll)}
              >
                {showAll ? "Vezi mai puțin" : "Vezi toate articolele"}
              </Button>
            </Box>
          )}
        </>
      )}
      {!loading && articles.length === 0 && (
        <Box pt={4}>
          <Typography>
            {hasActiveFilters(filters)
              ? RESPONSE.NO_RESULTS
              : RESPONSE.NO_ARTICLES}
          </Typography>
        </Box>
      )}
    </PaddedContainer>
  );
}
