import React, { useEffect, useState } from "react";
import {
  Box,
  capitalize,
  IconButton,
  Drawer,
  Toolbar,
  useMediaQuery,
} from "@mui/material";
import { makeStyles, useTheme } from "@mui/styles";
import AppBar from "@mui/material/AppBar";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import { useLocation } from "react-router";
import { useScrollPosition } from "@n8tb1t/use-scroll-position";
import { Link } from "react-router-dom";

// Components
import NavScrollableTabs from "../components/navigation/NavScrollableTabs";

// Others
import { ROUTES } from "../constants/routes";
import InteractiveSearch from "../components/data-display/input/InteractiveSearch";
import Logo from "../components/Logo";

const TopBarNavigation = () => {
  const classes = useStyles();
  const [isOpen, toggleIsOpen] = useState(false);
  const [isHomePage, toggleIsHomePage] = useState(false);
  const [isScrolled, toggleIsScrolled] = useState(false);
  const [activeLink, setActiveLink] = useState("");

  const { pathname } = useLocation();

  const theme = useTheme();
  const matchesDesktop = useMediaQuery(theme.breakpoints.up("md"));

  const routesToTabsMap = ROUTES.map(({ name, link, id}) => ({
    name,
    link,
    id,
    html: <div className={classes.link}>{capitalize(name)}</div>,
  }));

  const isActiveLink = (pathname, id) => {
    return (
      pathname.split('/').some((token) => token?.toLowerCase() === id.toLowerCase())
    ); // cut the first '/'
  };

  useEffect(() => {
    toggleIsHomePage(pathname === "/");
    window.scrollTo(0, 0);

    setActiveLink(ROUTES.find(({ id }) => isActiveLink(pathname, id)));
  }, [pathname]);
  useScrollPosition(
    ({ prevPos, currPos }) => {
      toggleIsScrolled(-currPos.y > 70);
    },
    [isScrolled]
  );

  const onTabClick = () => toggleIsOpen(false);

  const desktopTopNavigation = (isHomePage, isScrolled) => (
    <AppBar
      position="sticky"
      className={
        isHomePage && !isScrolled ? classes.appbarHome : classes.appbar
      }
      classes={{
        root: isHomePage && !isScrolled ? classes.paperHome : classes.paper,
      }}
    >
      <Box
        className={isHomePage && !isScrolled ? classes.rootHome : classes.root}
      >
        {((isHomePage && isScrolled) || !isHomePage) && (
          <Link to={"/"}>
            <div className={classes.logo}>
              <Logo />
              <div className={classes.title}>Dr. Mihai Vârlan</div>
            </div>
          </Link>
        )}

        <Box display={"flex"}>
          <NavScrollableTabs
            tabs={routesToTabsMap}
            initialValue={activeLink || routesToTabsMap[0]}
          />

          <InteractiveSearch />
        </Box>
      </Box>
    </AppBar>
  );

  const mobileTopNavigation = (isHomePage, isScrolled) => (
    <>
      <AppBar
        position="sticky"
        className={
          isHomePage && !isScrolled ? classes.appbarHome : classes.appbar
        }
        classes={{
          root: isHomePage && !isScrolled ? classes.paperHome : classes.paper,
        }}
      >
        <Toolbar
          className={
            isHomePage && !isScrolled ? classes.toolbarHome : classes.toolbar
          }
        >
          {((isHomePage && isScrolled) || !isHomePage) && (
            <div className={classes.logo}>
              <Logo />
              <div className={classes.title}>Dr. Mihai Vârlan</div>
            </div>
          )}

          <Box display={"flex"}>
            <InteractiveSearch />

            <IconButton color="inherit" onClick={() => toggleIsOpen(true)}>
              <MenuIcon />
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>
      <Drawer
        anchor={"top"}
        open={isOpen}
        onClose={() => toggleIsOpen(false)}
        className={classes.drawer}
        classes={{
          paper: classes.mobileMenu,
        }}
      >
        <IconButton color="primary" onClick={() => toggleIsOpen(false)}>
          <CloseIcon />
        </IconButton>
        <NavScrollableTabs
          tabs={routesToTabsMap}
          initialValue={activeLink || routesToTabsMap[0]}
          onTabClickCallback={onTabClick}
        />
      </Drawer>
    </>
  );

  return (
    <>
      {matchesDesktop
        ? desktopTopNavigation(isHomePage, isScrolled)
        : mobileTopNavigation(isHomePage, isScrolled)}
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  appbar: {
    backgroundColor: `${theme.palette.common.base} !important`,
    height: 74,
    display: "flex",
    justifyContent: "center",
    "& *": {
      transition: "all 0.3s ease-in-out",
    },
  },
  appbarHome: {
    backgroundColor: "transparent !important",
  },
  toolbar: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  toolbarHome: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  drawer: {
    backgroundColor: theme.palette.primary.light,
  },
  paper: {
    boxShadow: "8px 8px 15px 0 rgba(212, 218, 230, 0.4) !important",
    [theme.breakpoints.down("md")]: {
      boxShadow: "8px 8px 15px 0 rgba(212, 218, 230, 0.5) !important",
    },
  },
  paperHome: {
    boxShadow: "none !important",
  },
  root: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    padding: theme.spacing(2),
    alignItems: "center",
    backgroundColor: `${theme.palette.common.base} !important`,
    zIndex: 2000,
  },
  rootHome: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
    padding: theme.spacing(2),
    alignItems: "center",
    backgroundColor: "transparent !important",
    zIndex: 2000,
  },
  logo: {
    marginRight: theme.spacing(2),
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  title: {
    fontFamily: "'Italianno', cursive",
    fontSize: 37,
    lineHeight: "10px",
    fontWeight: '600',
    color: theme.palette.primary.darkText,
    whiteSpace: 'nowrap',

    [theme.breakpoints.down("md")]: {
      fontSize: "2rem",
      lineHeight: "2rem",
    },
  },
  link: {
    padding: `0 ${theme.spacing(1)}`,
    color: theme.palette.common.darkBlue,
    textDecoration: "none",
  },
  mobileMenu: {
    paddingTop: theme.spacing(3),
  },
}));

export default TopBarNavigation;
