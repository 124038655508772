import { Grid } from "@mui/material";
import { format } from "date-fns";
import React from "react";
import { DATE_FORMAT } from "../../../util/date-utils";

// Components
import Card from "./Card";

export default function ReviewCard({ text, author, date }) {
  return (
    <Card padded className="review-card">
      <div>
        <i className="material-icons-round">format_quote</i>
        <em>{text}</em>
      </div>
      <Grid container spacing={5} alignContent="center" alignItems="center">
        <Grid item xs={12} sm={6} align="left">
          {format(new Date(date), DATE_FORMAT)}
        </Grid>
        <Grid item xs={12} sm={6} align="right">
          {author}
        </Grid>
      </Grid>
    </Card>
  );
}
