import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useHistory, useLocation } from "react-router-dom";
import { ADMIN_ROUTES } from "../../../types/enum";

export default function GatedApp({ children }) {
  const location = useLocation();
  const history = useHistory();
  
  const authenticated = false;

  useEffect(() => {
      if (!authenticated) {
        history.push(ADMIN_ROUTES.LOGIN);
      }
  }, [location, authenticated, history]);

  return (<>{children}</>
  );
}

GatedApp.propTypes = {
  children: PropTypes.node.isRequired,
};

