import React from "react";
import { Grid, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";

// Container
import { PaddedContainer } from "../../common/layout/containers";

export default function ContactPage() {
  const classes = useStyles();

  return (
    <PaddedContainer>
      <Typography variant="h6" gutterBottom>
        Contact
      </Typography>
      <hr />

      <Grid
        container
        className={classes.root}
        alignContent="center"
        alignItems="center"
      >
        <Grid item sm={12} md>
          <div className={classes.address}>
            <p>CENTRUL MEDICAL BIOINVEST</p>
            <p>Str. Episcop Roman Ciorogariu nr. 62</p>
            <p>410009 Oradea, Romania</p>
            <p>+40 259 412 295</p>
          </div>
        </Grid>
        <Grid item sm={12} md={9} className={classes.map}>
          <iframe
            title="contactAdr"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2717.715847751021!2d21.92645011580699!3d47.065424133197304!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x474648758d06e50f%3A0xd518b54b4355991b!2sBioinvest!5e0!3m2!1sen!2sro!4v1615660851397!5m2!1sen!2sro"
            width="800"
            height="400"
            style={{ border: 0 }}
            target="top"
            allowFullScreen={false}
            loading="lazy"
          ></iframe>
        </Grid>
      </Grid>
    </PaddedContainer>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(5),
    display: "flex",
    justifyContent: "space-between",

    "& iframe": {
      borderRadius: 4,
      width: "100%",
    },
  },
  address: {
    display: "flex",
    flexDirection: "column",
  },
  map: {
    width: "100%",
  }
}));
