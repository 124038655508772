import React from "react";
import { Box, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";

// Components
import { PaddedContainer } from "../../common/layout/containers";
import Paragraph from "../../common/components/data-display/Paragraph";

// data
import { interviewQuestions } from "../../data-source/bio-page-interview";

export default function BioPage() {
  const classes = useStyles();

  return (
    <PaddedContainer>
      <Typography variant="h6">
        Despre mine
      </Typography>
      <hr />

      <Box pt={2}>
        <Typography variant="h6" gutterBottom align="center" className={classes.title}>
          <b>Medicul e ocrotit de rugăciunile fiecărui pacient salvat</b>
        </Typography>
        <Typography variant="body1" gutterBottom align="center">
          Un interviu acordat <i>"logopaper"</i>, consemnat de Maria Paşcalău
        </Typography>

        <br />
        <br />

        <img
          src="/media/bio/profile.jpg"
          alt="surgery"
          className={classes.imageRight}
        />

        <Typography gutterBottom align="justify" paragraph>
          <i>
            "Ce este vocația? Cuvântul vocaţie vine din latinescul vocare, care
            înseamnă chemare. Denotă o voce care te cheamă către un scop unic.
            Vocaţia este cea care scoate la iveală tot ce este mai bun din tine.
          </i>
        </Typography>

        <Typography gutterBottom align="justify" paragraph>
          <i>
            Este acea combinaţie unică de talente şi abilităţi pe care le ai şi
            pe care le poţi dezvolta, care sunt şi vor fi stâlpul tău de
            sprijin, indiferent de ceea ce se întâmplă în lumea exterioară. Ele
            sunt puterea ta şi poţi apela la ele ori de câte ori ai nevoie.
          </i>
        </Typography>

        <Typography gutterBottom align="justify" paragraph>
          <i>
            Medicina este şi ea, la rândul ei, o vocaţie frumoasă, care
            presupune mult efort. Dacă tot vorbim de medicină şi de eforturile
            pe care aceasta le presupune, haideți să aflăm cât mai multe secrete
            despre această meserie nobilă."
          </i>
        </Typography>

        <br />

        {interviewQuestions.slice(0, 2).map(({ subtitle, text }) => (
          <>
            <Paragraph subtitle={subtitle} text={text} />
            <br />
          </>
        ))}

        <img
          src="/media/carousel/5.jpg"
          alt="surgery"
          className={classes.imageLeft}
        />

        {interviewQuestions.slice(2, 7).map(({ subtitle, text }) => (
          <>
            <Paragraph subtitle={subtitle} text={text} />
            <br />
          </>
        ))}

        <img
          src="/media/bio/bio.jpeg"
          alt="surgery"
          className={classes.imageRight}
        />

        {interviewQuestions
          .slice(7, interviewQuestions.length)
          .map(({ subtitle, text }) => (
            <>
              <Paragraph subtitle={subtitle} text={text} />
              <br />
            </>
          ))}

        <br />

        <Typography variant="body2" gutterBottom align="right" paragraph>
          Aici găseşti interviul integral:{" "}
          <a
            className={classes.link}
            target={"_blank"}
            rel="noreferrer"
            href="https://logopaper.com/2020/07/29/dr-mihai-varlan-medicul-e-ocrotit-de-rugaciunile-fiecarui-pacient-salvat/?fbclid=IwAR36EFaxu7IE9GCk4WcGqI5gshivyCSuFqI66vCIlpZl49zeZ6JW1lt3-Lk"
          >
            Dr. Mihai Vârlan: „Medicul e ocrotit de rugăciunile fiecărui pacient
            salvat”
          </a>
          , publicat de logopaper la 29 Iulie 2020
        </Typography>
      </Box>
    </PaddedContainer>
  );
}

const useStyles = makeStyles((theme) => ({
  imageLeft: {
    width: "50%",
    float: "left",
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
    marginRight: theme.spacing(4),
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
  imageRight: {
    width: "50%",
    float: "right",
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
    marginLeft: theme.spacing(4),
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
  link: {
    color: `${theme.palette.primary.darkText} !important`,
    fontWeight: "bold",
    "&:hover": {
      textDecoration: "underline !important",
      cursor: "pointer !important",
      color: `${theme.palette.primary.main} !important`,
    },
  },
  title: {
    color: `${theme.palette.primary.darkText} !important`
  }
}));
