import { Chip } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";

export default function CustomChip({ clickable = false, ...props }) {
  const classes = useStyles();
  return (
    <Chip
      variant="outlined"
      color="primary"
      {...props}
      className={`${classes.chip} ${clickable ? classes.clickable : ""} `}
    />
  );
}

const useStyles = makeStyles((theme) => ({
  chip: {
    color: `${theme.palette.primary.dark} !important`,
    fontWeight: 600,
    fontSize: "16px !important",
    cursor: "pointer !important",
    width: "100%",

    "&:hover": {
      backgroundColor: theme.palette.primary.light,
    },
  },
}));
