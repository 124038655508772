import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import DateAdapter from "@mui/lab/AdapterDateFns";

import HomePage from "./app/modules/views/home/HomePage";
import Layout from "./app/modules/common/layout/Layout";
import AdminLayout from "./app/modules/admin/layout/AdminLayout";
import ContactPage from "./app/modules/views/contact/ContactPage";
import ArticlePage from "./app/modules/views/articles/ArticlePage";
import ArticleDetailPage from "./app/modules/views/articles/ArticleDetailPage";
import VideoGalleryPage from "./app/modules/views/video/VideoGalleryPage";
import ReviewPage from "./app/modules/views/reviews/ReviewPage";
import BioPage from "./app/modules/views/bio/BioPage";
import VideoDetailPage from "./app/modules/views/video/VideoDetailPage";

import LoginPage from "./app/modules/admin/login/LoginPage";
import AdminArticlesPage from "./app/modules/admin/articles/AdminArticlesPage";
import AdminVideosPage from "./app/modules/admin/videos/AdminVideosPage";
import AdminHomePage from "./app/modules/admin/home/AdminHomePage";

import theme from "./app/assets/theme/theme";
import { ROUTES, ADMIN_ROUTES, ADMIN_PREFIX } from "./app/types/enum";

import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import ArticlesProvider from "./app/modules/views/articles/providers";
import GalleryPage from "./app/modules/views/gallery/GalleryPage";
import GatedApp from "./app/modules/admin/auth/GatedApp";
import AdminArticleEdit from "./app/modules/admin/articles/ArticleEdit";
import AdminVideoEdit from "./app/modules/admin/videos/AdminVideoEdit";
import LocalizationProvider from "@mui/lab/LocalizationProvider";

const AdminModule = () => (
  <GatedApp>
    <AdminLayout>
      <Switch>
        <Route exact path={ADMIN_ROUTES.LOGIN}>
          <LoginPage />
        </Route>
        <Route exact path={ADMIN_ROUTES.ARTICLES}>
          <AdminArticlesPage />
        </Route>
        <Route exact path={ADMIN_ROUTES.VIDEOS}>
          <AdminVideosPage />
        </Route>

        <Route exact path={ADMIN_ROUTES.ARTICLE}>
          <AdminArticleEdit key={"1"} />
        </Route>
        <Route exact path={ADMIN_ROUTES.VIDEO}>
          <AdminVideoEdit key={"1"} />
        </Route>

        <Route path={`${ADMIN_ROUTES.ARTICLE}/:id`}>
          <AdminArticleEdit key={"2"} />
        </Route>
        <Route path={`${ADMIN_ROUTES.VIDEO}/:id`}>
          <AdminVideoEdit key={"2"} />
        </Route>

        <Route path={ADMIN_ROUTES}>
          <AdminHomePage />
        </Route>
      </Switch>
    </AdminLayout>
  </GatedApp>
);

const ArticlesModule = () => (
  <ArticlesProvider>
    <Switch>
      <Route exact path={ROUTES.ARTICLES}>
        <ArticlePage />
      </Route>
      <Route exact path={`${ROUTES.ARTICLES}/:id`}>
        <ArticleDetailPage />
      </Route>

      <Redirect to={ROUTES.HOME} />
    </Switch>
  </ArticlesProvider>
);

const WebsiteModule = () => (
  <Layout>
    <Switch>
      <Route exact path={ROUTES.CONTACT}>
        <ContactPage />
      </Route>
      <Route path={ROUTES.ARTICLES}>
        <ArticlesModule />
      </Route>
      <Route exact path={ROUTES.BIO}>
        <BioPage />
      </Route>
      <Route path={ROUTES.GALLERY}>
        <GalleryPage />
      </Route>
      <Route exact path={ROUTES.REVIEWS}>
        <ReviewPage />
      </Route>
      <Route exact path={`${ROUTES.VIDEOS}/:nr`}>
        <VideoDetailPage />
      </Route>
      <Route exact path={ROUTES.VIDEOS}>
        <VideoGalleryPage />
      </Route>

      <Route exact path={ROUTES.HOME}>
        <HomePage />
      </Route>

      <Redirect to={ROUTES.HOME} />
    </Switch>
  </Layout>
);

function App() {
  return (
    <LocalizationProvider dateAdapter={DateAdapter}>
      <ThemeProvider theme={theme}>
        <Router>
          <Switch>
            <Route path={ADMIN_PREFIX}>
              <AdminModule />
            </Route>

            <Route path={ROUTES.HOME}>
              <WebsiteModule />
            </Route>
          </Switch>
        </Router>
      </ThemeProvider>
    </LocalizationProvider>
  );
}

export default App;
