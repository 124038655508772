import { Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { format } from "date-fns";
import React from "react";
import { Link } from "react-router-dom";
import { mergeParagraphs } from "../../../util/articles-utils";
import { DATE_FORMAT } from "../../../util/date-utils";
import { ROUTES } from "../../../../types/enum";

// Components
import Card from "./Card";

export default function ArticleEntry({
  id,
  title,
  date,
  paragraphs,
  image,
  className,
}) {
  const classes = useStyles();

  return (
    <Card className={`article-card ${className}`}>
      <Link to={`${ROUTES.ARTICLES}/${id}`}>
        <Box display="flex" flexDirection="column" p={2}>
          {image && <img src={image} alt="alt" />}
          <h6 className={classes.articleTitle}>{title}</h6>
          <div className={classes.articleText}>
            {mergeParagraphs(paragraphs)}{" "}
          </div>
          <div className={classes.readMore}>[...Citește mai departe]</div>
          <div className={classes.date}>{format(new Date(date), DATE_FORMAT)}</div>
        </Box>
      </Link>
    </Card>
  );
}

const useStyles = makeStyles((theme) => ({
  articleTitle: {
    height: 40,
  },
  articleText: {
    fontSize: "16px",
    fontFamily: `${theme.fonts.article} !important`,
    height: 95,
    overflow: "hidden",

    [theme.breakpoints.down("md")]: {
      fontSize: "15px",
    },
  },
  readMore: {
    paddingBottom: theme.spacing(2),
    fontWeight: "bold",
    whiteSpace: "nowrap",
    fontSize: "16px",
  },
  date: {
    fontSize: "14px",
  },
}));
