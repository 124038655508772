import React from "react";
import PropTypes from "prop-types";
import { Box } from "@mui/material";
import { makeStyles } from "@mui/styles";

// Components
import Footer from "./Footer";
import TopBarNavigation from "./TopBarNavigation";
import palette from "../../../assets/theme/palette";

// Others

export default function Layout({ children }) {
  const classes = useStyles();

  return (
    <Box bgcolor={palette.common.base}>
      <TopBarNavigation />

      <Box className={classes.container}>{children}</Box>

      <Footer />
    </Box>
  );
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

const useStyles = makeStyles((theme) => ({
  container: {
    minHeight: "75vh",

    [theme.breakpoints.down("sm")]: {
      paddingTop: theme.spacing(5),
    },
  },
}));
